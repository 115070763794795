<div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <div class="d-flex">
        <app-go-back></app-go-back>
        <h1 class="h2 text-info">Performance - <span class="fs-3 text-secondary">{{ point_of_sale_name }}</span></h1>
    </div>
    <div class="btn-toolbar mb-2 mb-md-0">
        <div class="row">
        <div class="col">
            <form class="row row-cols-sm-auto">
            <div class="col-12 d-flex flex-row">
                <label class="col-sm-2 col-form-label fw-bold text-info">Du</label>
                <div class="input-group">
                <input ngb-dp-today [(ngModel)]="startDate" (ngModelChange)="onDateChange($event)" class="form-control"
                    placeholder="dd/mm/yyyy" name="start_date" [(ngModel)]="startDate" ngbDatepicker #d="ngbDatepicker">
                <button class="btn btn-outline-secondary calendar bg-info" (click)="d.toggle()" type="button">
                    <i-bs name="calendar3" class="text-white"></i-bs>
                </button>
                </div>
            </div>
            </form>
        </div>
        <div class="col">
            <form class="row row-cols-sm-auto">
            <div class="col-12 d-flex flex-row">
                <label class="col-sm-2 col-form-label fw-bold text-info">Au</label>
                <div class="input-group">
                <input ngb-dp-today [(ngModel)]="endDate" (ngModelChange)="onDateChange($event)" class="form-control"
                    placeholder="dd/mm/yyyy" name="end_date" [(ngModel)]="endDate" ngbDatepicker #e="ngbDatepicker">
                <button class="btn btn-outline-secondary calendar bg-info" (click)="e.toggle()" type="button">
                    <i-bs name="calendar3" class="text-white"></i-bs>
                </button>
                </div>
            </div>
            </form>
        </div>
        </div>
    </div>
    </div>
</div>
<div *ngIf="loading" class="d-flex justify-content-center">
    <div class="spinner-border mx-auto mt-5 text-success" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<table  *ngIf="loaded" class="table align-middle table-hover">
    <thead class="table-light">
      <tr class="rounded">
        <th scope="col">SKU</th>
        <th scope="col">Stock Final</th>
        <th scope="col" class="text-center">Stock Initial</th>
        <th scope="col" class="text-center">Rotation</th>
        <th scope="col" class="text-center">Bon de commande</th>
        <th scope="col" class="text-center">Déclassés</th>
        <th scope="col" class="text-center">Montant déclassé</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let performance of this.performances">
        <th scope="row"class="fw-bold text-info">
          {{ performance.sku_sage }}
        </th>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{  performance.sp_final_stock }}
          </span>
        </td>
        <td class="text-primary fw-bold">
            <span class="float-end">
              {{  performance.is_quantity }}
            </span>
          </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{ performance.sp_rotation }}
          </span>
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{ performance.op_quantity }}
          </span>
        </td>
        <td class="text-primary fw-bold">
            <span class="float-end">
              {{ performance.rp_quantity }} 
            </span>
        </td>
        <td class="text-primary fw-bold">
            <span class="float-end">
              {{ performance.rp_amount | currency:'XOF':'symbol-narrow':'':'fr-FR' }} 
            </span>
        </td>
      </tr>
    </tbody>
  </table>
