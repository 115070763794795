import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, share } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginationInterface } from 'src/app/interfaces/pagination-interface';
import { OtherService } from 'src/app/services/others.service';
import { CountryStore } from 'src/app/stores/country.store';
import { OtherCountryStore } from 'src/app/stores/other-country.store';
import { OtherTypeStore } from 'src/app/stores/other-type.store';
import { OtherStore } from 'src/app/stores/other.store';
import { Country, ModalConfig, OtherCountry } from 'src/app/types';
import { ActionEventType, ColumnDef, EColumnType } from 'src/app/utils';

const enum EModalFormType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

@Component({
  selector: 'app-others-country-list',
  templateUrl: './others-country-list.component.html',
  styleUrls: ['./others-country-list.component.scss'],
})
export class OthersCountryListComponent implements OnInit {
  /* vars */
  selectedCountry!: number;
  selectedOtherType!: number;
  selectedCountryOther: any = null;
  othersList: any[] = [];
  error: string = '';

  /* Subscribers */
  countries$: Observable<Country[]> | undefined;
  otherCountries$: Observable<OtherCountry[]> | undefined;
  otherTypes$: Observable<any[]> | undefined;
  loading$: Observable<boolean> | undefined;
  error$: Observable<string> | undefined;

  /* Filter and pagination props */
  pagination!: PaginationInterface;

  /* Modal configuration */
  modalConfig!: ModalConfig;
  modalForm!: FormGroup;
  modalFormType: string = EModalFormType.CREATE;
  modalFormDisplay: boolean = false;
  modalFormStatusActionMessage: string = '';

  /*  Inputs  */
  @ViewChild('nGmodal') private modalComponent!: ModalComponent;

  /* Column definitions */
  columnDefs: ColumnDef[] = [
    new ColumnDef({
      label: 'Id',
      name: 'id',
      positionClass: ['fw-normal'],
    }),
    new ColumnDef({
      label: 'Pays',
      name: 'country_name',
    }),
    new ColumnDef({
      label: 'Produit',
      name: 'other_libelle',
    }),
    new ColumnDef({
      label: 'Categorie',
      name: 'other_type_libelle',
    }),
    new ColumnDef({
      label: 'Statut',
      name: 'active',
      type: EColumnType.SWITCH,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-end'],
    }),
    new ColumnDef({
      label: 'Actions',
      type: EColumnType.ACTION,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-end'],
      actions: [
        {
          baseUrl: '',
          text: 'Modifier',
          functionToInvoque: 'openUpdateModal',
          actionToogleableIndex: 0,
          class: 'btn btn-warning btn-sm',
          permissions: ['CAN_UPDATE_OTHER_COUNTRY'],
        },
        {
          baseUrl: '',
          text: 'Supprimer',
          functionToInvoque: 'delete',
          actionToogleableIndex: 0,
          class: 'btn btn-danger btn-sm',
          permissions: ['CAN_DELETE_OTHER_COUNTRY'],
        },
      ],
    }),
  ];

  /* Table configuration */
  tableActionsFunction(event: ActionEventType) {
    switch (event.action.functionToInvoque) {
      case 'openUpdateModal':
        this.openUpdateModal(event);
        break;
      case 'delete':
        this.openDeleteModal(event);
        break;
      default:
        break;
    }
  }

  constructor(
    private otherService: OtherService,
    private formBuilder: FormBuilder,
    private countryStore: CountryStore,
    private otherStore: OtherStore,
    private otherCountryStore: OtherCountryStore,
    private otherTypeStore: OtherTypeStore,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.countries$ = this.countryStore.select((state) => state.countries);
    this.otherCountries$ = this.otherCountryStore.select(
      (state) => state.otherCountries
    );
    this.otherCountryStore
      .select((state) => state.pagination)
      .subscribe((pagination) => {
        if (pagination === undefined) return;
        this.pagination = pagination;
      });
    this.otherTypes$ = this.otherTypeStore.select((state) => state.otherTypes);
    this.loading$ = this.otherCountryStore.select((state) => state.loading);
    this.loading$.subscribe((loading) => {
      if (loading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });

    this.loadCountryOthers();
    this.loadCountries();
    this.getAllOthersProducts();
    this.loadOtherTypes();
    this.initForm();
  }

  initForm() {
    this.modalForm = this.formBuilder.group({
      id: [''],
      country_id: ['', Validators.required],
      other_type_id: ['', Validators.required],
      other_id: ['', Validators.required],
      active: [true],
    });
  }

  paginate(pagination: any) {
    this.otherCountryStore.setInitial();
    this.otherCountryStore.getOtherCountries({
      limit: pagination.items!,
      page: pagination.page,
    });
  }

  async openModal() {
    return await this.modalComponent.open();
  }

  loadCountryOthers() {
    this.otherCountryStore.getOtherCountries({
      limit: 15,
      page: this.pagination.page,
    });
  }

  loadCountries() {
    this.countryStore.getCountries({});
  }

  getAllOthersProducts() {
    this.spinner.show();
    const param = { page: 1, limit: 1000 };
    this.otherService.getOthers(param).subscribe({
      next: (response) => {
        this.othersList = response.data;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.error = error;
      },
    });
  }

  loadOtherTypes() {
    this.otherTypeStore.getOtherTypes({});
  }

  onFilterChange() {
    this.otherCountryStore.setInitial();
    this.otherCountryStore.getOtherCountries(this.buildQueryParams());
  }

  openCreateModal() {
    this.modalFormType = EModalFormType.CREATE;
    this.modalConfig = {
      modalTitle: 'Ajouter un autre produit',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-info text-white',
      cancelButtonClass: 'btn-secondary',
      submitButtonClass: 'btn-primary text-white',
      disableSubmitButton: () => !this.modalForm.valid,
      onSubmit: () => this.create(),
    };

    this.modalForm = this.formBuilder.group({
      id: [''],
      country_id: ['', Validators.required],
      other_id: ['', Validators.required],
      other_type_id: ['', Validators.required],
      active: [true],
    });
    this.openModal();
  }

  openUpdateModal(event: ActionEventType) {
    this.openModal();
    this.modalConfig = {
      modalTitle: 'Modifier un autre produit',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-warning text-white',
      cancelButtonClass: 'btn-sm btn-outline-secondary',
      submitButtonClass: 'btn-sm btn-warning text-white',
      disableSubmitButton: () => !this.modalForm.valid,
      onSubmit: () => this.update(),
    };
    this.modalFormType = EModalFormType.UPDATE;
    this.selectedCountryOther = event.rowData;
    this.modalForm = this.formBuilder.group({
      id: [this.selectedCountryOther.id, Validators.required],
      country_id: [this.selectedCountryOther.country_id, Validators.required],
      other_id: [this.selectedCountryOther.other_id, Validators.required],
      other_type_id: [
        this.selectedCountryOther.other_type_id,
        Validators.required,
      ],
      active: [true],
    });
  }

  openDeleteModal(event: ActionEventType) {
    this.selectedCountryOther = event.rowData;
    this.modalFormType = EModalFormType.DELETE;
    this.modalConfig = {
      modalTitle: 'Supprimer un autre produit',
      submitButtonLabel: 'Supprimer',
      cancelButtonLabel: 'Annuler',
      cancelButtonClass: 'btn-sm btn-outline-secondary',
      submitButtonClass: 'btn-sm btn-danger text-white',
      headerClass: 'bg-danger text-white',
      onSubmit: () => this.delete(this.selectedCountryOther),
    };
    this.openModal();
  }

  create() {
    this.otherCountryStore.addOtherCountry(this.modalForm.value);
  }

  update() {
    this.otherCountryStore.updateOtherCountry(this.modalForm.value);
  }

  delete(otherCountry: OtherCountry) {
    this.otherCountryStore.deleteOtherCountry(otherCountry);
  }

  buildQueryParams() {
    const queryParams: any = {
      limit: this.pagination.items || 15,
      page: this.pagination.page,
    };
    if (this.selectedCountry) {
      queryParams.country_id = this.selectedCountry;
    }
    if (this.selectedOtherType) {
      queryParams.other_type_id = this.selectedOtherType;
    }
    return queryParams;
  }
}
