import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PaginationInterface } from 'src/app/interfaces/pagination-interface';
import { OtherTypeStore } from 'src/app/stores/other-type.store';
import { ModalConfig, OtherType } from 'src/app/types';
import { ActionEventType, ColumnDef, EColumnType } from 'src/app/utils';

const enum EModalFormType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

@Component({
  selector: 'app-others-types',
  templateUrl: './others-types.component.html',
  styleUrls: ['./others-types.component.scss'],
})
export class OthersTypesComponent implements OnInit {
  otherTypes$: Observable<OtherType[]> | undefined;
  types$: Observable<string[]> | undefined;
  loading$: Observable<boolean> | undefined;
  error$: Observable<string> | undefined;

  currentOtherType: any = null;

  /*  Inputs  */
  @ViewChild('nGmodal') private modalComponent!: ModalComponent;

  /* Modal configuration */
  modalConfig!: ModalConfig;
  modalForm!: FormGroup;
  modalFormType: string = EModalFormType.CREATE;
  modalFormDisplay: boolean = false;
  modalFormStatusActionMessage: string = '';

  /* Filter and pagination props */
  pagination!: PaginationInterface;

  /* Column definitions */
  columnDefs: ColumnDef[] = [
    new ColumnDef({
      label: 'Id',
      name: 'id',
      positionClass: ['fw-normal'],
    }),
    new ColumnDef({
      label: 'Libelle',
      name: 'libelle',
    }),
    new ColumnDef({
      label: 'Statut',
      name: 'active',
      type: EColumnType.SWITCH,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-end'],
    }),
    new ColumnDef({
      label: 'Actions',
      type: EColumnType.ACTION,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-end'],
      actions: [
        {
          baseUrl: '',
          text: 'Modifier',
          functionToInvoque: 'openUpdateModal',
          actionToogleableIndex: 0,
          class: 'btn btn-warning btn-sm',
          permissions: ['CAN_UPDATE_OTHER_TYPE'],
        },
        {
          baseUrl: '',
          text: 'Supprimer',
          functionToInvoque: 'delete',
          actionToogleableIndex: 0,
          class: 'btn btn-danger btn-sm',
          permissions: ['CAN_DELETE_OTHER_TYPE'],
        },
      ],
    }),
  ];

  /* Table configuration */
  tableActionsFunction(event: ActionEventType) {
    switch (event.action.functionToInvoque) {
      case 'openUpdateModal':
        this.openUpdateModal(event);
        break;
      case 'delete':
        this.openDeleteModal(event);
        break;
      default:
        break;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private otherTypeStore: OtherTypeStore
  ) {}

  ngOnInit(): void {
    this.otherTypes$ = this.otherTypeStore.select((state) => state.otherTypes);
    this.loading$ = this.otherTypeStore.select((state) => state.loading);
    this.otherTypeStore
      .select((state) => state.pagination)
      .subscribe((pagination) => {
        if (pagination === undefined) return;
        this.pagination = pagination;
      });
    this.loading$.subscribe((loading) => {
      if (loading) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
    this.error$ = this.otherTypeStore.select((state) => state.error);
    this.initForm();
    this.loadOtherTypes();
  }

  initForm() {
    this.modalForm = this.formBuilder.group({
      id: [''],
      libelle: ['', Validators.required],
      active: [true],
    });
  }

  paginate(pagination: any) {
    this.otherTypeStore.setInitial();
    this.otherTypeStore.getOtherTypes({
      limit: pagination.items!,
      page: pagination.page,
    });
  }

  loadOtherTypes(): void {
    this.otherTypeStore.getOtherTypes(this.pagination);
  }

  async openModal() {
    return await this.modalComponent.open();
  }

  openCreateModal() {
    this.openModal();
    this.modalFormType = EModalFormType.CREATE;
    this.modalConfig = {
      modalTitle: 'Ajouter une catégorie',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-info text-white',
      cancelButtonClass: 'btn-secondary',
      submitButtonClass: 'btn-primary text-white',
      disableSubmitButton: () => !this.modalForm.valid,
      onSubmit: () => this.create(),
    };

    this.modalForm = this.formBuilder.group({
      id: [''],
      libelle: ['', Validators.required],
      active: [true],
    });
  }

  openUpdateModal(event: ActionEventType) {
    this.openModal();
    this.modalConfig = {
      modalTitle: 'Modifier une catégorie',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-warning text-white',
      cancelButtonClass: 'btn-sm btn-outline-secondary',
      submitButtonClass: 'btn-sm btn-warning text-white',
      disableSubmitButton: () => !this.modalForm.valid,
      onSubmit: () => this.update(),
    };
    this.modalFormType = EModalFormType.UPDATE;
    this.currentOtherType = event.rowData;
    this.modalForm = this.formBuilder.group({
      id: [this.currentOtherType.id, Validators.required],
      libelle: [this.currentOtherType.libelle, Validators.required],
      active: [this.currentOtherType.active],
    });
  }

  openDeleteModal(event: ActionEventType) {
    this.currentOtherType = event.rowData;
    this.modalFormType = EModalFormType.DELETE;
    this.modalConfig = {
      modalTitle: 'Supprimer une catégorie',
      submitButtonLabel: 'Supprimer',
      cancelButtonLabel: 'Annuler',
      cancelButtonClass: 'btn-sm btn-outline-secondary',
      submitButtonClass: 'btn-sm btn-danger text-white',
      headerClass: 'bg-danger text-white',
      onSubmit: () => this.delete(this.currentOtherType),
    };
    this.openModal();
  }

  create() {
    this.otherTypeStore.addOtherType(this.modalForm.value);
  }

  update() {
    this.otherTypeStore.updateOtherType(this.modalForm.value);
  }

  delete(otherType: OtherType) {
    this.otherTypeStore.deleteOtherType(otherType);
  }
}
