<div
  *ngIf="getSectionAuthorizeRole(['admin', 'Précommandeur'])"
  class="container mt-2 mb-3"
>
  <h2 class="mb-3">MT</h2>
  <div class="row">
    <ng-template ngFor let-item [ngForOf]="CARD_ITEMS">
      <div
        *ngIf="
          getSectionAuthorizeRole(item.authorizedRoles) &&
          getCardSectionSection(item.sections, ESection.MT)
        "
        class="col-md-4"
      >
        <a [routerLink]="item.link" [class]="item.linkClass">
          <div class="card p-3 mb-2">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">
                <div class="icon">
                  <img [src]="item.image" alt="image" height="35" />
                </div>
                <div class="ms-2 c-details">
                  <h6 class="mb-0">{{ item.title }}</h6>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <h3 class="heading text-info">
                {{ item.text1 }}<br />{{ item.text2 }}
              </h3>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
  </div>
</div>
<div
  *ngIf="getSectionAuthorizeRole(['admin', 'superviseur'])"
  class="container mt-2 mb-3"
>
  <h2 class="mb-3">Tradi</h2>
  <div class="row">
    <ng-template ngFor let-item [ngForOf]="CARD_ITEMS">
      <div
        *ngIf="
          getSectionAuthorizeRole(item.authorizedRoles) &&
          getCardSectionSection(item.sections, ESection.TRADI)
        "
        class="col-md-4"
      >
        <a [routerLink]="item.link" [class]="item.linkClass">
          <div class="card p-3 mb-2">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">
                <div class="icon">
                  <img [src]="item.image" alt="image" height="35" />
                </div>
                <div class="ms-2 c-details">
                  <h6 class="mb-0">{{ item.title }}</h6>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <h3 class="heading text-info">
                {{ item.text1 }}<br />{{ item.text2 }}
              </h3>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
  </div>
</div>
<div
  *ngIf="getSectionAuthorizeRole(['admin', 'superviseur', 'chef de quart'])"
  class="container mt-2 mb-3"
>
  <h2 class="mb-3">Logistique</h2>
  <div class="row">
    <ng-template ngFor let-item [ngForOf]="CARD_ITEMS">
      <div
        *ngIf="
          getSectionAuthorizeRole(item.authorizedRoles) &&
          getCardSectionSection(item.sections, ESection.LOGISTIQUE)
        "
        class="col-md-4"
      >
        <a [routerLink]="item.link" [class]="item.linkClass">
          <div class="card p-3 mb-2">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">
                <div class="icon">
                  <img [src]="item.image" alt="image" height="35" />
                </div>
                <div class="ms-2 c-details">
                  <h6 class="mb-0">{{ item.title }}</h6>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <h3 class="heading text-info">
                {{ item.text1 }}<br />{{ item.text2 }}
              </h3>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
  </div>
</div>
<div
  *ngIf="getSectionAuthorizeRole(['admin'])"
  class="container mt-2 mb-3"
>
  <h2 class="mb-3">Administration</h2>
  <div class="row">
    <ng-template ngFor let-item [ngForOf]="CARD_ITEMS">
      <div
        *ngIf="
          getSectionAuthorizeRole(item.authorizedRoles) &&
          getCardSectionSection(item.sections, ESection.ADMIN)
        "
        class="col-md-4"
      >
        <a [routerLink]="item.link" [class]="item.linkClass">
          <div class="card p-3 mb-2">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">
                <div class="icon">
                  <img [src]="item.image" alt="image" height="35" />
                </div>
                <div class="ms-2 c-details">
                  <h6 class="mb-0">{{ item.title }}</h6>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <h3 class="heading text-info">
                {{ item.text1 }}<br />{{ item.text2 }}
              </h3>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
  </div>
</div>
