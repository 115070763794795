import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ClusterPaginationInterface, EClusteringMode } from 'src/app/interfaces/clusters-interface';
import { PaginationInterface } from 'src/app/interfaces/pagination-interface';
import { ClusterService } from 'src/app/services/cluster.service';
import { ClusterStore } from 'src/app/stores/cluster.store';
import { CountryStore } from 'src/app/stores/country.store';
import { Cluster, Country, ModalConfig } from 'src/app/types';
import { ActionEventType, ColumnDef, EColumnType } from 'src/app/utils';

const enum EModalFormType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

@Component({
  selector: 'app-clusters-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  /* Observables */
  clusters$: Observable<Cluster[]> | undefined;
  countries$: Observable<Country[]> | undefined;
  formSuccess$: Observable<boolean> | undefined;
  loading$: Observable<boolean> | undefined;
  error$: Observable<string> | undefined;

  /* Modal configuration */
  modalConfig!: ModalConfig;
  modalForm!: FormGroup;
  modalFormType: string = EModalFormType.CREATE;
  modalFormDisplay: boolean = false;
  modalFormStatusActionMessage: string = '';

  /* Filter and pagination props */
  pagination: ClusterPaginationInterface = {
    page: 1,
    limit: 10,
    clustering_modes: [EClusteringMode.DEFAULT]
  };

  /* Column definitions */
  columnDefs: ColumnDef[] = [
    new ColumnDef({
      label: 'Id',
      name: 'id',
      positionClass: ['fw-normal'],
    }),
    new ColumnDef({
      label: 'Libelle',
      name: 'cluster',
    }),
    new ColumnDef({
      label: 'Libelle En',
      name: 'cluster_en',
    }),
    new ColumnDef({
      label: 'Passage',
      name: 'passage',
    }),
    new ColumnDef({
      label: 'Pays',
      name: 'country_name',
    }),
    new ColumnDef({
      label: 'Statut',
      name: 'activate',
      type: EColumnType.SWITCH,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-end'],
    }),
    new ColumnDef({
      label: 'Actions',
      type: EColumnType.ACTION,
      hearderPositionClass: ['text-center'],
      positionClass: ['text-end'],
      actions: [
        {
          baseUrl: '',
          text: 'Modifier',
          functionToInvoque: 'openUpdateModal',
          actionToogleableIndex: 0,
          class: 'btn btn-warning btn-sm',
          permissions: ['CAN_UPDATE_CLUSTER'],
        },
        {
          baseUrl: '',
          text: 'Supprimer',
          functionToInvoque: 'delete',
          actionToogleableIndex: 0,
          class: 'btn btn-danger btn-sm',
          permissions: ['CAN_DELETE_CLUSTER'],
        },
      ],
    }),
  ];

  /* Table configuration */
  tableActionsFunction(event: ActionEventType) {
    switch (event.action.functionToInvoque) {
      case 'openUpdateModal':
        this.openUpdateModal(event);
        break;
      case 'delete':
        this.openDeleteModal(event);
        break;
      default:
        break;
    }
  }

  /* vars */
  currentCluster: any = null;

  /*  Inputs  */
  @ViewChild('nGmodal') private modalComponent!: ModalComponent;

  constructor(
    private formBuilder: FormBuilder,
    private clusterStore: ClusterStore,
    private spinnerService: NgxSpinnerService,
    private countryStore: CountryStore
  ) {
  }

  ngOnInit(): void {

    this.clusters$ = this.clusterStore.select((state) => state.clusters);
    this.formSuccess$ = this.clusterStore.select((state) => state.formSuccess);
    this.countries$ = this.countryStore.select((state) => state.countries);
    this.loading$ = this.clusterStore.select((state) => state.loading);
    this.clusterStore
      .select((state) => state.pagination)
      .subscribe((pagination) => {
        if (pagination === undefined) return;
        this.pagination = pagination;
      });
    this.formSuccess$.subscribe((formSuccess) => {
      if (formSuccess) {
        this.closeModal();
      }
    });
    this.loading$.subscribe((loading) => {
      if (loading) {
        this.spinnerService.show();
      } else {
        this.spinnerService.hide();
      }
    });

    this.error$ = this.clusterStore.select((state) => state.error);
    this.loadClusters();
    this.loadCountries();
    this.initForm();
  }

  initForm() {
    this.modalForm = this.formBuilder.group({
      id: [''],
      cluster: ['', Validators.required],
      cluster_en: ['', Validators.required],
      passage: ['', Validators.required],
      country_id: ['', Validators.required],
      activate: [true],
    });
  }

  paginate(pagination: any) {
    this.clusterStore.setInitial();
    this.clusterStore.getClusters({
      ...this.pagination,
      limit: pagination.items!,
      page: pagination.page,
    });
  }

  loadClusters(): void {
    this.clusterStore.getClusters(this.pagination);
  }

  loadCountries() {
    this.countryStore.getCountries({});
  }

  openUpdateModal(event: ActionEventType) {
    this.openModal();
    this.modalConfig = {
      modalTitle: 'Modifier le cluster',
      submitButtonLabel: 'Enregistrer',
      cancelButtonLabel: 'Annuler',
      headerClass: 'bg-warning text-white',
      cancelButtonClass: 'btn-sm btn-outline-secondary',
      submitButtonClass: 'btn-sm btn-warning text-white',
      disableSubmitButton: () => !this.modalForm.valid,
      onSubmit: () => this.update(),
    };
    this.modalFormType = EModalFormType.UPDATE;
    this.currentCluster = event.rowData;
    this.modalForm = this.formBuilder.group({
      id: [this.currentCluster.id, Validators.required],
      cluster: [this.currentCluster.cluster, Validators.required],
      cluster_en: [this.currentCluster.cluster_en, Validators.required],
      country_id: [this.currentCluster.country_id, Validators.required],
      passage: [this.currentCluster.passage, Validators.required],
      activate: [this.currentCluster.activate],
    });
  }

  openDeleteModal(event: ActionEventType) {
    this.currentCluster = event.rowData;
    this.modalFormType = EModalFormType.DELETE;
    this.modalConfig = {
      modalTitle: 'Supprimer le cluster',
      submitButtonLabel: 'Supprimer',
      cancelButtonLabel: 'Annuler',
      cancelButtonClass: 'btn-sm btn-outline-secondary',
      submitButtonClass: 'btn-sm btn-danger text-white',
      headerClass: 'bg-danger text-white',
      onSubmit: () => this.delete(this.currentCluster),
    };
    this.openModal();
  }

  async openModal() {
    return await this.modalComponent.open();
  }

  async closeModal() {
    return await this.modalComponent.close();
  }
  // create() {
  //   this.clusterStore.addCluster(this.modalForm.value);
  // }

  update() {
    this.clusterStore.updateCluster(this.modalForm.value);
  }

  delete(cluster: Cluster) {
    this.clusterStore.deleteCluster(cluster);
  }
}
