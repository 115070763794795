<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <div class="d-flex">
    <app-go-back></app-go-back>
    <h1 class="h2 text-info">Autres produits par pays</h1>
  </div>
  <div class="btn-toolbar mb-2 mb-md-0">
    <div class="row">
      <div class="col">
        <div class="row form-group mx-3">
          <label for="inputEmail3" class="col-form-label fw-bold text-info"
            >Pays</label
          >
          <div class="col">
            <ng-select
              [(ngModel)]="selectedCountry"
              (change)="onFilterChange()"
            >
              <ng-option
                *ngFor="let country of countries$ | async"
                [value]="country.id"
                >{{ country.name }}</ng-option
              >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row form-group mx-3">
          <label for="inputEmail3" class="col-form-label fw-bold text-info"
            >Catérogie</label
          >
          <div class="col">
            <ng-select
              [(ngModel)]="selectedOtherType"
              (change)="onFilterChange()"
            >
              <ng-option
                *ngFor="let otherType of otherTypes$ | async"
                [value]="otherType.id"
                >{{ otherType.libelle }}</ng-option
              >
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-is-guarded [permissions]="['CAN_CREATE_OTHER_COUNTRY']">
    <a
      class="btn btn-info btn-sm text-sm text-white fw-bold mx-1"
      (click)="openCreateModal()"
      >Nouvel autre produit</a
    >
  </app-is-guarded>
</div>
<div *ngIf="otherCountries$ | async as otherCountries">
  <app-table
    *ngIf="otherCountries.length"
    [columnDefs]="columnDefs"
    [tableData]="otherCountries"
    (actionInvoqueFunctionEvent)="tableActionsFunction($event)"
  ></app-table>
  <ng-template [ngIf]="error$ | async">
    <app-empty-card
      [icon]="'exclamation-circle'"
      [message]="'Aucune catégorie trouvée'"
    ></app-empty-card>
  </ng-template>
  <app-pagination
  *ngIf="otherCountries.length"
    [pagination]="pagination"
    (paginationEvent)="paginate($event)"
  ></app-pagination>
</div>
<modal #nGmodal [modalConfig]="modalConfig" class="modal-dialog-centered">
  <form
    *ngIf="modalFormType === 'CREATE' || modalFormType === 'UPDATE'"
    [formGroup]="modalForm"
  >
    <div class="mb-3">
      <label for="country_id" class="form-label fw-bold text-secondary"
        >Pays:
      </label>
      <select
        formControlName="country_id"
        class="form-control form-control-sm"
        aria-label="Choisir un pays"
      >
        <option [ngValue]="undefined" selected>Choisir un pays</option>
        <option
          *ngFor="let country of countries$ | async"
          [ngValue]="country.id"
        >
          {{ country.name }}
        </option>
      </select>
    </div>
    <div class="mb-3">
      <label for="country_id" class="form-label fw-bold text-secondary"
        >Autre produit:
      </label>
      <select
        formControlName="other_id"
        class="form-control form-control-sm"
        aria-label="Choisir un pays"
      >
        <option [ngValue]="undefined" selected>Choisir un autre produit</option>
        <option *ngFor="let other of othersList" [ngValue]="other.id">
          {{ other.libelle }}
        </option>
      </select>
    </div>
    <div class="d-flex mb-3">
      <div class="col me-2">
        <label for="other_type_id" class="form-label fw-bold text-secondary"
          >Catégorie:
        </label>
        <select
          formControlName="other_type_id"
          class="form-control form-control-sm"
          aria-label="Choisir un pays"
        >
          <option [ngValue]="undefined" selected>Choisir une categorie</option>
          <option
            *ngFor="let otherType of otherTypes$ | async"
            [ngValue]="otherType.id"
          >
            {{ otherType.libelle }}
          </option>
        </select>
      </div>
    </div>
    <div class="d-flex mb-3">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexCheckDefault"
          formControlName="active"
        />
        <label
          class="form-check-label fw-bold text-secondary"
          for="flexCheckDefault"
        >
          Statut
        </label>a
      </div>
    </div>
  </form>
  <p></p>
  <p *ngIf="modalFormType === 'DELETE'">
    <strong
      >Voulez-vous vraiment supprimer cet autre produit
      <span class="text-danger">"#{{ selectedCountryOther.id }}"</span>?</strong
    >
  </p>
  <p class="mt-4" *ngIf="!modalFormDisplay && modalFormStatusActionMessage">
    {{ modalFormStatusActionMessage }}
  </p>
</modal>
