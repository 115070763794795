<div class="relative">
  <div
    class="d-flex flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
  >
    <h1 class="h3 text-info mx-3 mb-3">Bons de commande</h1>
    <div class="btn-toolbar mb-2 mb-md-0 mx-3">
      <div class="row">
        <div class="col-sm-6">
          <div class="row form-group">
            <label
              for="inputEmail3"
              class="col-sm-2 col-form-label fw-bold text-info"
              >du
            </label>
            <div class="col">
              <div class="input-group">
                <input
                  ngb-dp-today
                  [(ngModel)]="date"
                  (ngModelChange)="onDatePreorderChange($event)"
                  class="form-control form-control-sm rounded-left"
                  placeholder="dd/mm/yyyy"
                  name="dp"
                  [(ngModel)]="date"
                  ngbDatepicker
                  #d="ngbDatepicker"
                />
                <button
                  class="btn btn-info btn-sm btn-outline-secondary calendar text-white"
                  (click)="d.toggle()"
                  type="button"
                >
                  <i-bs name="calendar3"></i-bs>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row form-group">
            <label
              for="inputEmail3"
              class="col-sm-3 col-form-label fw-bold text-info"
              >Secteur
            </label>
            <div class="col">
              <select
                [(ngModel)]="sector"
                (ngModelChange)="onSectorChange($event)"
                class="form-control form-control-sm"
                aria-label="Choisir un secteur"
              >
                <option [ngValue]="undefined" selected>
                  Choisir un secteur
                </option>
                <option *ngFor="let sector of sectors" [ngValue]="sector.id">
                  {{ sector.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table align-middle mb-5">
      <thead class="table-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">PDV</th>
          <th scope="col">Livraison</th>
          <th scope="col">Statut de validation</th>
          <th scope="col">C.A. Prévu</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let pointOfSale of this.pointOfSales">
          <th scope="row" class="fw-normal">{{ pointOfSale.id }}</th>
          <td class="fw-bold text-info">
            {{ pointOfSale.name }}
          </td>
          <td class="fw-bold text-info">
            <i-bs name="truck"></i-bs>
          </td>
          <td class="fw-bold">
            <span
              class="badge bg-success badge-outlined"
              *ngIf="pointOfSale.is_ordered === true"
              >Commande passée</span
            >
            <span
              class="badge bg-warning badge-outlined"
              *ngIf="
                pointOfSale.is_preordered === true &&
                pointOfSale.is_ordered === false
              "
              >En attente de la commande</span
            >
            <span
              class="badge bg-info badge-outlined"
              *ngIf="
                !pointOfSale.sale_id &&
                !pointOfSale.is_preordered &&
                !pointOfSale.is_ordered &&
                pointOfSale.no_id === ''
              "
              >Non visité</span
            >
            <span
              class="badge bg-danger badge-outlined"
              *ngIf="
                !pointOfSale.is_preordered &&
                !pointOfSale.is_ordered &&
                pointOfSale.no_id !== ''
              "
            >
              Visité</span
            >
          </td>
          <td class="text-primary fw-bold">
            <span class="float-end">{{
              getCA(pointOfSale, pointOfSale.sales)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}</span>
          </td>
          <td class="text-primary">
            <div class="float-end">
              <div
                *ngIf="isInThePast()"
                class="btn-group btn-group-sm"
                role="group"
              >
                <a
                  class="btn btn-info btn-sm fw-bold text-white"
                  [queryParams]="{ name: pointOfSale.name, date: date }"
                  routerLink="/orders/{{ pointOfSale.id }}"
                >
                  Consulter
                </a>
              </div>
              <div
                *ngIf="!isInThePast()"
                class="btn-group btn-group-sm"
                role="group"
              >
                <a
                  class="btn {{
                    !pointOfSale.is_ordered ? 'btn-info' : 'btn-warning'
                  }}  btn-sm fw-bold text-white"
                  [queryParams]="{ name: pointOfSale.name, date: date }"
                  routerLink="/orders/{{ pointOfSale.id }}/new"
                >
                  {{ !pointOfSale.is_ordered ? "Commander" : "Modifier" }}
                </a>
                <div
                  *ngIf="
                    pointOfSale.is_ordered == true ||
                    pointOfSale.is_preordered == true
                  "
                  class="btn-group btn-group-sm"
                  ngbDropdown
                  role="group"
                  aria-label="Button group with nested dropdown"
                >
                  <button
                    type="button"
                    class="btn btn-danger text-white"
                    ngbDropdownToggle
                  >
                    ...
                  </button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <a
                      ngbDropdownItem
                      *ngIf="
                        !(
                          pointOfSale.is_preordered === true &&
                          pointOfSale.is_ordered === false
                        )
                      "
                      (click)="
                        change_preorder_to_order(
                          pointOfSale.sale_id,
                          pointOfSale.sector_id
                        )
                      "
                      >Repasser en précommande</a
                    >
                    <a
                      ngbDropdownItem
                      (click)="
                        delete_order(pointOfSale.sale_id, pointOfSale.sector_id)
                      "
                      >Supprimer</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p class="placeholder-glow" *ngIf="loading">
    <span class="placeholder col-12 placeholder-sm"></span>
    <span class="placeholder col-12 placeholder-sm"></span>
    <span class="placeholder col-12 placeholder-sm"></span>
    <span class="placeholder col-12 placeholder-sm"></span>
    <span class="placeholder col-12 placeholder-sm"></span>
  </p>
  <app-is-guarded [permissions]="['CAN_CREATE_ORDER_PREORDER']">
    <div
      *ngIf="!isInThePast()"
      class="bottom-bar shadow-sm fixed-bottom col-md-9 ms-sm-auto col-lg-10 px-md-4"
    >
      <div class="justify-content-end mb-md-0 mx-1 d-flex">
        <div class="row">
          <div class="col-md-7 mx-2 my-2">
            <div class="input-group">
              <label
                for="inputEmail3"
                class="col-form-label col-sm-5 fw-bold text-info"
                >Date de livraison
              </label>
              <input
                ngb-dp-today
                [(ngModel)]="deliveryDate"
                (ngModelChange)="onDateChange($event)"
                [minDate]="minDate"
                class="form-control form-control-sm rounded-left col-sm-1"
                placeholder="dd/mm/yyyy"
                name="dp"
                [(ngModel)]="deliveryDate"
                ngbDatepicker
                #f="ngbDatepicker"
              />
              <button
                class="btn btn-sm btn-info btn-outline-secondary calendar text-white"
                (click)="f.toggle()"
                type="button"
              >
                <i-bs name="calendar3"></i-bs>
              </button>
            </div>
          </div>
          <div class="col-md-4 mx-2 my-2">
            <div class="">
              <button
                type="button"
                class="btn btn-info rounded btn-sm fw-bold text-white fs-6 text-wrap w-100"
                (click)="createForecast()"
                [disabled]="!canForecast()"
              >
                Créer une prévision
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-is-guarded>
</div>
