import { ImportCoordinatesModule } from 'src/app/components/import-coordinates/import-coordinates.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountriesComponent } from './countries.component';
import { ListComponent } from './list/list.component';
import { GoBackModule } from 'src/app/components/go-back/go-back.module';
import { TableModule } from 'src/app/components/table/table.module';
import { EmptyCardModule } from 'src/app/components/empty-card/empty-card.module';
import { PaginationModule } from 'src/app/components/pagination/pagination.module';
import { CountriesRoutingModule } from './countries-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { NgxBootstrapIconsModule, arrowUp, circleFill, search } from 'ngx-bootstrap-icons';

const icons = {
  search,
  circleFill,
  arrowUp,
};
@NgModule({
  declarations: [
    CountriesComponent,
    ListComponent
  ],
  imports: [
    CommonModule,
    CountriesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    GoBackModule,
    TableModule,
    EmptyCardModule,
    ModalComponent,
    PaginationModule,
    NgxBootstrapIconsModule.pick(icons),
    ImportCoordinatesModule
  ]
})
export class CountriesModule { }
