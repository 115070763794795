import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../services/authentication.service';
import { MenuItem } from '../utils';
import { Router, RoutesRecognized } from '@angular/router';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  currentUser: User | undefined;
  isCollapsed: boolean = false;
  resized: boolean = false;
  currentApplicationVersion = environment.appVersion;

  MENU_ITEMS: MenuItem[] = [
    {
      url: '',
      icon: 'house',
      title: 'Accueil',
      authorizedRole: ['all'],
      routerLinkActiveOptions: true,
    },
    {
      url: '',
      icon: 'list',
      title: 'Indicateurs Marketing',
      collapsedId: 'others',
      authorizedRole: ['admin'],
      routerLinkActiveOptions: false,
      children: [
        {
          url: 'others',
          icon: 'boxes',
          title: 'Autres produits',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: true,
        },
        {
          url: 'others/others-types',
          icon: 'grid',
          title: 'Categories de produits',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'others/others-countries',
          icon: 'flag',
          title: 'Autres produits par pays',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
      ],
    },
    {
      url: '',
      icon: 'graph-up',
      title: 'KPIs',
      collapsedId: 'kpis',
      authorizedRole: ['admin','all','Précommandeur'],
      routerLinkActiveOptions: false,
      children: [
        {
          url: 'goals',
          icon: 'speedometer',
          title: 'Objectifs',
          authorizedRole: ['all'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'performances',
          icon: 'graph-up',
          title: 'Performances',
          authorizedRole: ['admin', 'Précommandeur'],
          routerLinkActiveOptions: false,
        },
      ],
    },
    {
      url: '',
      icon: 'truck',
      title: 'Tournées & Chargements',
      collapsedId: 'tourneesAndLoadings',
      authorizedRole: ['admin','superviseur','chef de quart','chef de zone','Précommandeur'],
      routerLinkActiveOptions: false,
      children: [
        {
          url: 'tournees',
          icon: 'truck',
          title: 'Tournées',
          authorizedRole: ['admin','superviseur'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'unloading-bills',
          icon: 'truck',
          title: 'Factures de déchargement',
          authorizedRole: ['admin','superviseur', 'chef de quart'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'request-loadings/loading-requests',
          icon: 'truck',
          title: 'Demandes de chargements',
          authorizedRole: ['admin', 'superviseur', 'chef de quart'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'forecasts',
          icon: 'arrow-clockwise',
          title: 'Prévisions de chargement',
          authorizedRole: ['admin', 'superviseur', 'chef de zone'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'orders',
          icon: 'clipboard2-check',
          title: 'Bons de commande (MT)',
          authorizedRole: ['admin', 'Précommandeur'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'loadings',
          icon: 'truck',
          title: 'Chargements de camions (MT)',
          authorizedRole: ['admin', 'Précommandeur'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'request-loadings/loading-logistics',
          icon: 'truck',
          title: 'Chargements',
          authorizedRole: ['admin', 'superviseur', 'chef de quart'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'request-loadings/additionnal-loading-logistics',
          icon: 'truck',
          title: 'Chargements supplémentaires',
          authorizedRole: ['admin', 'superviseur', 'chef de quart'],
          routerLinkActiveOptions: false,
        },
      ],
    },
    {
      url: 'sales',
      icon: 'shop',
      title: 'Ventes',
      authorizedRole: ['admin', 'superviseur', 'Business Developper','Précommandeur'],
      routerLinkActiveOptions: false,
    },
    {
      url: '',
      icon: 'shop',
      title: 'Points de ventes',
      collapsedId: 'point_of_sales',
      authorizedRole: ['admin','superviseur','Business Developper', 'Précommandeur'],
      routerLinkActiveOptions: false,
      children: [
        {
          url: 'point_of_sales_tradi',
          icon: 'shop',
          title: 'Points de vente',
          authorizedRole: ['admin', 'superviseur', 'Business Developper'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'point-of-sales',
          icon: 'shop',
          title: 'MT',
          authorizedRole: ['admin', 'Précommandeur'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'trade-schedules',
          icon: 'calendar3',
          title: 'Calendrier (MT)',
          authorizedRole: ['admin', 'Précommandeur'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'payment-modes',
          icon: 'currency-exchange',
          title: 'Modes de paiements',
          authorizedRole: ['admin', 'Précommandeur'],
          routerLinkActiveOptions: false,
        },
      ],
    },
    {
      url: '',
      icon: 'diagram-2',
      title: 'Clustering',
      collapsedId: 'clusterisation',
      authorizedRole: ['admin'],
      routerLinkActiveOptions: false,
      children: [
        {
          url: 'clusters',
          icon: 'diagram-2',
          title: 'Clusters',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'clusters-rtm',
          icon: 'diagram-2',
          title: 'RTM',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
      ],
    },
    {
      url: '',
      icon: 'boxes',
      title: 'Gestion de stock',
      collapsedId: 'stock-management',
      authorizedRole: ['admin', 'chef de quart'],
      routerLinkActiveOptions: false,
      children: [
        {
          url: 'stock-movements',
          icon: 'boxes',
          title: 'Mouvements de stock',
          authorizedRole: ['admin', 'chef de quart'],
          routerLinkActiveOptions: true,
        },
        {
          url: 'tours/stock-receipt-list',
          icon: 'boxes',
          title: 'Réceptions de stock',
          authorizedRole: ['admin', 'chef de quart'],
          routerLinkActiveOptions: true,
        },
        {
          url: 'stock-controls',
          icon: 'truck',
          title: 'Inventaires',
          authorizedRole: ['admin', 'chef de quart'],
          routerLinkActiveOptions: false,
        }
      ],
    },
    {
      url: '',
      icon: 'list',
      title: 'Référentiels',
      collapsedId: 'référentiels',
      authorizedRole: ['admin', 'chef de quart'],
      routerLinkActiveOptions: false,
      children: [
        {
          url: 'countries',
          icon: 'flag',
          title: 'Pays',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'commercial-regions',
          icon: 'flag',
          title: 'Régions Commerciales',
          authorizedRole: ['admin', 'chef de quart'],
          routerLinkActiveOptions: true,
        },
        {
          url: 'depots',
          icon: 'bank',
          title: 'Dépôts',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'zone-supervisors',
          icon: 'map',
          title: 'Zones Superviseurs',
          authorizedRole: ['admin', 'chef de quart'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'sectors',
          icon: 'columns-gap',
          title: 'Secteurs',
          authorizedRole: ['admin', 'chef de quart'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'zones',
          icon: 'pin-map',
          title: 'Zones',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'catalogs',
          icon: 'flag',
          title: 'Catalogues',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'categories',
          icon: 'diagram-2',
          title: 'Catégories',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'products',
          icon: 'diagram-2',
          title: 'Produits & Accessoires',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },    
      ],
    },
    {
      url: 'tour-management',
      icon: 'list',
      title: 'Plannifications logistiques',
      collapsedId: 'tour-management',
      authorizedRole: ['admin'],
      routerLinkActiveOptions: false,
      children: [
        {
          url: 'gestion-of-trucks',
          icon: 'truck',
          title: 'Camions',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'list-tour-logistic',
          icon: 'flag',
          title: 'Tournées',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'plannings',
          icon: 'calendar3',
          title: 'Plannings hebdomadaire',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: true,
        },
      ],
    },
    {
      url: '',
      icon: 'gear',
      title: 'Administration',
      authorizedRole: ['admin'],
      routerLinkActiveOptions: false,
      collapsedId: 'administration',
      children: [
        {
          url: 'users',
          icon: 'people',
          title: 'Utilisateurs',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'roles',
          icon: 'person-badge',
          title: 'Rôles',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'permissions',
          icon: 'key',
          title: 'Permissions',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
        },
      ],
    },
    {
      url: '',
      icon: 'list',
      title: 'Synchronisation X3',
      collapsedId: 'x3_synchronization',
      authorizedRole: ['admin', 'chef de quart'],
      routerLinkActiveOptions: false,
      children: [
        {
          url: 'sage-synchronizations/loadings',
          icon: 'truck',
          title: 'Chargements',
          authorizedRole: ['admin', 'chef de quart'],
          routerLinkActiveOptions: true,
        },
        {
          url: 'sage-synchronizations/additionnal_loadings',
          icon: 'truck',
          title: 'Chargements Supplémentaires',
          authorizedRole: ['admin', 'chef de quart'],
          routerLinkActiveOptions: true,
        },
      ],
    },
    {
      url: '',
      icon: 'currency-dollar',
      title: 'Facturation & Tréso',
      authorizedRole: ['admin', 'responsable treso', 'DAF'],
      routerLinkActiveOptions: false,
      collapsedId: 'facturation',
      children: [
        {
          url: 'transactions',
          icon: 'currency-exchange',
          title: 'Transactions',
          authorizedRole: ['admin', 'responsable treso', 'DAF'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'seller-references',
          icon: 'people',
          title: 'Référentiel vendeurs',
          authorizedRole: ['admin', 'responsable treso', 'DAF'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'accounting-entries',
          icon: 'file-text',
          title: 'Ecritures comptables',
          authorizedRole: ['admin', 'responsable treso', 'DAF'],
          routerLinkActiveOptions: false,
        },
        {
          url: 'billing-transactions',
          icon: 'currency-exchange',
          title: 'Dépôts & paiements marchands',
          authorizedRole: ['admin', 'responsable treso', 'DAF'],
          routerLinkActiveOptions: false,
        },
        {
          url: '',
          icon: 'gear',
          title: 'Administration',
          authorizedRole: ['admin'],
          routerLinkActiveOptions: false,
          collapsedId: 'administration',
          children: [
            {
              url: 'users',
              icon: 'people',
              title: 'Gestion des utilisateurs',
              authorizedRole: ['admin'],
              routerLinkActiveOptions: false,
            },
            {
              url: 'roles',
              icon: 'person-badge',
              title: 'Gestion des rôles',
              authorizedRole: ['admin'],
              routerLinkActiveOptions: false,
            },
            {
              url: 'permissions',
              icon: 'key',
              title: 'Gestion des permissions',
              authorizedRole: ['admin'],
              routerLinkActiveOptions: false,
            },
          ],
        },
      ],
    },
    {
      url: 'survey',
      icon: 'question',
      title: 'Sondages',
      authorizedRole: ['admin'],
      routerLinkActiveOptions: false,
    },
  ];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof RoutesRecognized))
      .subscribe((event) => {
        this.authenticationService.me();
      });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  canViewMenu(menu: MenuItem) {
    return (
      menu.authorizedRole.includes(this.currentUser!.role?.libelle!) ||
      menu.authorizedRole.includes('all')
    );
  }

  resize() {
    this.resized = !this.resized;
  }
}
