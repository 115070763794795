import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { IDaysDateInWeek } from '../interfaces/logistics-tours';
import { ELogisticTourStatus, ELogisticTourStatusFR, LogisticTour } from '../models/logistic-tour';
import { EPointOfDeliveryStatus, EPointOfDeliveryStatusFr, PointOfDelivery } from '../models/point-of-delivery';
import { EWeeklyDayPassage, EWeeklyDayPassageFR } from '.';

// custom validator to check that two fields match
export const MustMatch: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  return password?.value === confirmPassword?.value
    ? null
    : { notmatched: true };
};

export const removeUndefinedProperties = (obj: any): any => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      obj[key] = [];
    }
  }
  return obj;
}

export function roundPositiveDivisions(numerator: number, denominator: number) {
  const result = numerator / denominator;
  const integer = Math.floor(result);
  const decimalPart = result - Math.floor(result);

  if (decimalPart >= 0.5 && integer > 0) {
    return Math.ceil(result);
  } else if (decimalPart >= 0.5 && integer === 0) {
    return 1;
  }
  else {
    return integer
  }
}

export function calculPallet(numerator: number, denominator: number) {
  const result = numerator / denominator;
  if (denominator > 0) {
    return Math.round(result * 10) / 10;
  } else return 0;
}
export const arrayEquals = (a: any[], b: any[]): boolean => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export type Month = { id: number; label: string };
export type Days = { id: number; label: string };
export type Year = { id: number; label: string };

export const months: Month[] = [
  { id: 1, label: 'Janvier' },
  { id: 2, label: 'Février' },
  { id: 3, label: 'Mars' },
  { id: 4, label: 'Avril' },
  { id: 5, label: 'Mai' },
  { id: 6, label: 'Juin' },
  { id: 7, label: 'Juillet' },
  { id: 8, label: 'Août' },
  { id: 9, label: 'Septembre' },
  { id: 10, label: 'Octobre' },
  { id: 11, label: 'Novembre' },
  { id: 12, label: 'Décembre' },
];

export const formatDate = (date: any) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export function getStartAndEndOfWeek(date: Date): { startDate: string; endDate: string } {
  const currentDate = new Date(date.getTime());
  const dayOfWeek = currentDate.getDay();

  // Trouver le lundi de cette semaine
  currentDate.setDate(currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Ajoute -6 si dimanche
  const startDate = new Date(currentDate.getTime()); // Créer une nouvelle instance pour le début
  const formattedStartDate = startDate.toISOString().split('T')[0];

  // Calculer le dimanche de cette semaine
  currentDate.setDate(currentDate.getDate() + 6);
  const endDate = new Date(currentDate.getTime()); // Créer une nouvelle instance pour la fin
  const formattedEndDate = endDate.toISOString().split('T')[0];

  return { startDate: formattedStartDate, endDate: formattedEndDate };
}

export function extractDateAndTime(dateTimeString: string): { date: string; time: string } {
  const dateTime = new Date(dateTimeString);

  // Extraction de la date
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  // Extraction de l'heure
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;

  return { date: formattedDate, time: formattedTime };
}

export function extractDateAndTimeFR(dateTimeString: string): { date: string; time: string } {
  const dateTime = new Date(dateTimeString);

  // Extraction de la date
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const formattedDate = `${day}/${month}/${year}`;

  // Extraction de l'heure
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;

  return { date: formattedDate, time: formattedTime };
}

export const isInThePast = (date: any): boolean => {
  let inPast: boolean = false;
  let today: Date = new Date();
  let dateValue: Date = new Date(date);
  today.setHours(0, 0, 0, 0);

  if (dateValue < today) {
    inPast = true;
  }

  return inPast;
};

export const isDirty = (
  firstArray: any[],
  secondArray: any[],
  matchingKey: any,
  changing_key: any
): boolean => {
  const difference = [];
  for (const fAItem of firstArray) {
    const currentItem = secondArray.find(
      (f) => f[matchingKey] === fAItem[matchingKey]
    );
    if (currentItem[changing_key] !== fAItem[changing_key]) {
      difference.push(fAItem);
    }
  }

  return difference.length > 0;
};


export const translateLogisticTourStatus = (
  status: keyof typeof ELogisticTourStatus
) => {
  return ELogisticTourStatusFR[status];
};

export const translatePointOfDeliveryStatus = (
  status: keyof typeof EPointOfDeliveryStatus
) => {
  return EPointOfDeliveryStatusFr[status];
};

export const translateWeeklyDayPassage = (
  day: keyof typeof EWeeklyDayPassage
) => {
  return EWeeklyDayPassageFR[day];
};

export const trunkText = (text: string, maxLength: number = 25) =>
  text?.length > maxLength ? `${text.substring(0, maxLength - 1)} ...` : text;

export const getLastDeliveryPointVehicleAndUserActors = (tour: LogisticTour) => {
  const item = tour.point_of_deliveries[tour.point_of_deliveries.length - 1]
  return {
    driver: item.driver,
    conveyor: item.conveyor,
    carrier_truck: item.carrier_truck,
    road_tractor: item.road_tractor,
    semi_trailer: item.semi_trailer,
  }
}

export const getFirstDeliveryPointOfDelivery = (tour: LogisticTour) => {
  return tour.point_of_deliveries[0]
}

export const checkIfVehivuleAndConveyorsPresentInPointOfDeliveries = (tour: LogisticTour) => {
  return tour.point_of_deliveries.every((point) =>
  (point.carrier_truck != null ? point.conveyor != null && point.driver != null
    : point.semi_trailer != null && point.road_tractor != null && point.conveyor != null && point.driver != null))
}


export const isCarrierTruckChanged = (values: any, point_of_delivery: PointOfDelivery) => {
  return (
    values.truck_id != point_of_delivery.carrier_truck.id ||
    values.driver != point_of_delivery.driver.id ||
    values.conveyor != point_of_delivery.conveyor.id
  );
}

export const isRoadTractorChanged = (values: any, point_of_delivery: PointOfDelivery) => {
  return (
    values.truck_id != point_of_delivery.road_tractor.id ||
    values.truck_id_two != point_of_delivery.semi_trailer.id ||
    values.driver != point_of_delivery.driver.id ||
    values.conveyor != point_of_delivery.conveyor.id
  );
}

export const isNoChange = (values: any, point_of_delivery: PointOfDelivery) => {
  return (
    (point_of_delivery.road_tractor !== null && values.truck_id === point_of_delivery.road_tractor.id) &&
    (point_of_delivery.semi_trailer !== null && values.truck_id_two === point_of_delivery.semi_trailer.id) &&
    (point_of_delivery.driver !== null && values.driver === point_of_delivery.driver.id) &&
    (point_of_delivery.conveyor !== null && values.conveyor === point_of_delivery.conveyor.id)
  ) || (
      (point_of_delivery.carrier_truck !== null && values.truck_id === point_of_delivery.carrier_truck.id) &&
      (point_of_delivery.driver !== null && values.driver === point_of_delivery.driver.id) &&
      (point_of_delivery.conveyor !== null && values.conveyor === point_of_delivery.conveyor.id)
    );
}

export const isInfosTourItemNotNull = (point_of_delivery: PointOfDelivery) => {
  return (point_of_delivery.conveyor !== null && point_of_delivery.driver !== null
    && point_of_delivery.road_tractor !== null && point_of_delivery.carrier_truck !== null
    && point_of_delivery.semi_trailer !== null)
}


export function getMonthLabel(monthNumber: number) {
  const month = months.find((m) => m.id === monthNumber);
  return month ? month.label : null;
}

export const getWeekNumber = (date: Date) => {
  const dateObject = new Date(date);
  dateObject.setHours(0, 0, 0, 0);
  dateObject.setDate(
    dateObject.getDate() + 3 - ((dateObject.getDay() + 6) % 7)
  );
  const week1 = new Date(dateObject.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(
      ((dateObject.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
      7
    )
  );
};

export function getMonthsForWeek(weekDays: IDaysDateInWeek[]): string[] {
  const months: string[] = [];
  weekDays.forEach(dayInfo => {
    if (!months.includes(dayInfo.monthLabel)) {
      months.push(dayInfo.monthLabel);
    }
  });
  return months;
}

export function getDaysFromStartOfYear(date: number, month: number, year: number): number {
  const startOfYear = new Date(year, 0, 1);
  const dateRef = new Date(year, month, date);
  const oneDay = 24 * 60 * 60 * 1000; // Nombre de millisecondes dans une journée
  const diffMilliseconds = dateRef.getTime() - startOfYear.getTime();
  return Math.round(diffMilliseconds / oneDay);
}

export function getFirstMondayOfYear(year: number) {
  const oneDay = 24 * 60 * 60 * 1000; // Nombre de millisecondes dans une journée
  const firstDayOfYear = new Date(year, 0, 1);

  // Trouver le jour de la semaine du premier jour de l'année
  let daysToMonday = (8 - firstDayOfYear.getDay()) % 7;
  if (daysToMonday === 0) {
    daysToMonday = 0; // Si le premier jour est un lundi, on le laisse tel quel
  }
  const firstMondayOfYear = new Date(firstDayOfYear.getTime() + daysToMonday * oneDay);
  return firstMondayOfYear;
}

export function getWeekDates(year: number, weekNumber: number): IDaysDateInWeek[] {
  const oneDay = 24 * 60 * 60 * 1000; // Nombre de millisecondes dans une journée
  const firstDayOfYear = getFirstMondayOfYear(year)
  const startDate = new Date(firstDayOfYear.getTime() + ((weekNumber - 1) * 7) * oneDay);
  // const startDate = new Date(firstDayOfYear.getTime() + ((weekNumber - 1) * 7) * oneDay);
  if (startDate.getFullYear() !== year) {
    // Si la semaine appartient à l'année suivante, réinitialiser à la première semaine de cette année
    return getWeekDates(startDate.getFullYear(), 1);
  }
  const weekDates = [];
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate.getTime() + i * oneDay);
    let day = currentDate.getDay();
    if (day === 0) {
      day = 7; // Si le jour est dimanche, assignez-lui la valeur 7 au lieu de 0
    }
    const monthNumber = currentDate.getMonth()
    const date = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    weekDates.push({ day: day, date: date, monthLabel: month, monthNumber, year, days: getDaysFromStartOfYear(date, monthNumber, year) + 1 });
  }
  return weekDates;
}

export type CountryCurrency = { name: string, iso: string, currency_iso: string, currency_name: string };

export const countriesWithCurrency: CountryCurrency[] = [
  {name: 'Afghanistan',	iso: 'AF',	currency_iso: 'AFN',	currency_name: 'Afghani'},
  {name: 'Afrique du Sud',	iso: 'ZA',	currency_iso: 'ZAR',	currency_name: 'Rand sud-africain'},
  {name: 'Albanie',	iso: 'AL',	currency_iso: 'ALL',	currency_name: 'Lek albanais'},
  {name: 'Algérie',	iso: 'DZ',	currency_iso: 'DZD',	currency_name: 'Dinar algérien'},
  {name: 'Allemagne',	iso: 'DE',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Andorre',	iso: 'AD',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Angola',	iso: 'AO',	currency_iso: 'AOA',	currency_name: 'Kwanza angolais'},
  {name: 'Antigua-et-Barbuda',	iso: 'AG',	currency_iso: 'XCD',	currency_name: 'Dollar des Caraïbes orientales'},
  {name: 'Arabie Saoudite',	iso: 'SA',	currency_iso: 'SAR',	currency_name: 'Riyal saoudien'},
  {name: 'Argentine',	iso: 'AR',	currency_iso: 'ARS',	currency_name: 'Peso argentin'},
  {name: 'Arménie',	iso: 'AM',	currency_iso: 'AMD',	currency_name: 'Dram'},
  {name: 'Australie',	iso: 'AU',	currency_iso: 'AUD',	currency_name: 'Dollar australien'},
  {name: 'Autriche',	iso: 'AT',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Azerbaïdjan',	iso: 'AZ',	currency_iso: 'AZN',	currency_name: 'Manat azerbaïdjanais'},
  {name: 'Bahamas',	iso: 'BS',	currency_iso: 'BSD',	currency_name: 'Dollar bahaméen'},
  {name: 'Bahreïn',	iso: 'BH',	currency_iso: 'BHD',	currency_name: 'Dinar bahreïni'},
  {name: 'Bangladesh',	iso: 'BD',	currency_iso: 'BDT',	currency_name: 'Taka'},
  {name: 'Barbade',	iso: 'BB',	currency_iso: 'BBD',	currency_name: 'Dollar barbadien'},
  {name: 'Belgique',	iso: 'BE',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Belize',	iso: 'BZ',	currency_iso: 'BZD',	currency_name: 'Dollar bélizien'},
  {name: 'Bénin',	iso: 'BJ',	currency_iso: 'XOF',	currency_name: 'Franc CFA'},
  {name: 'Bhoutan',	iso: 'BT',	currency_iso: 'BTN',	currency_name: 'Ngultrum'},
  {name: 'Biélorussie',	iso: 'BY',	currency_iso: 'BYN',	currency_name: 'Rouble biélorusse'},
  {name: 'Birmanie',	iso: 'MM',	currency_iso: 'MMK',	currency_name: 'Kyat'},
  {name: 'Bolivie',	iso: 'BO',	currency_iso: 'BOB',	currency_name: 'Boliviano bolivien'},
  {name: 'Bosnie-Herzégovine',	iso: 'BA',	currency_iso: 'BAM',	currency_name: 'Mark convertible'},
  {name: 'Botswana',	iso: 'BW',	currency_iso: 'BWP',	currency_name: 'Pula botswanais'},
  {name: 'Brésil',	iso: 'BR',	currency_iso: 'BRL',	currency_name: 'Réal brésilien'},
  {name: 'Brunei',	iso: 'BN',	currency_iso: 'BND',	currency_name: 'Dollar de Brunei'},
  {name: 'Bulgarie',	iso: 'BG',	currency_iso: 'BGN',	currency_name: 'Lev bulgare'},
  {name: 'Burkina Faso',	iso: 'BF',	currency_iso: 'XOF',	currency_name: 'Franc CFA'},
  {name: 'Burundi',	iso: 'BI',	currency_iso: 'BIF',	currency_name: 'Franc burundais'},
  {name: 'Cambodge',	iso: 'KH',	currency_iso: 'KHR',	currency_name: 'Riel'},
  {name: 'Cameroun',	iso: 'CM',	currency_iso: 'XAF',	currency_name: 'Franc CFA'},
  {name: 'Canada',	iso: 'CA',	currency_iso: 'CAD',	currency_name: 'Dollar canadien'},
  {name: 'Cap-Vert',	iso: 'CV',	currency_iso: 'CVE',	currency_name: 'Escudo cap-verdien'},
  {name: 'Chili',	iso: 'CL',	currency_iso: 'CLP',	currency_name: 'Peso chilien'},
  {name: 'Chine',	iso: 'CN',	currency_iso: 'CNY',	currency_name: 'Yuan'},
  {name: 'Chypre',	iso: 'CY',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Colombie',	iso: 'CO',	currency_iso: 'COP',	currency_name: 'Peso colombien'},
  {name: 'Comores',	iso: 'KM',	currency_iso: 'KMF',	currency_name: 'Franc comorien'},
  {name: 'Congo',	iso: 'CG',	currency_iso: 'XAF',	currency_name: 'Franc CFA'},
  {name: 'Corée du Nord',	iso: 'KP',	currency_iso: 'KPW',	currency_name: 'Won nord-coréen'},
  {name: 'Corée du Sud',	iso: 'KR',	currency_iso: 'KRW',	currency_name: 'Won sud-coréen'},
  {name: 'Costa Rica',	iso: 'CR',	currency_iso: 'CRC',	currency_name: 'Colon costaricien'},
  {name: 'Côte d\'Ivoire',	iso: 'CI',	currency_iso: 'XOF',	currency_name: 'Franc CFA'},
  {name: 'Croatie',	iso: 'HR',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Cuba',	iso: 'CU',	currency_iso: 'CUP',	currency_name: 'Peso cubain'},
  {name: 'Danemark',	iso: 'DK',	currency_iso: 'DKK',	currency_name: 'Couronne danoise'},
  {name: 'Djibouti',	iso: 'DJ',	currency_iso: 'DJF',	currency_name: 'Franc de Djibouti'},
  {name: 'Dominique',	iso: 'DM',	currency_iso: 'XCD',	currency_name: 'Dollar des Caraïbes orientales'},
  {name: 'Egypte',	iso: 'EG',	currency_iso: 'EGP',	currency_name: 'Livre égyptienne'},
  {name: 'Emirats Arabes Unis',	iso: 'AE',	currency_iso: 'AED',	currency_name: 'Dirham des émirats arabes unis'},
  {name: 'Equateur',	iso: 'EC',	currency_iso: 'USD',	currency_name: 'Dollar américain'},
  {name: 'Erythrée',	iso: 'ER',	currency_iso: 'ERN',	currency_name: 'Nakfa érythréen'},
  {name: 'Espagne',	iso: 'ES',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Estonie',	iso: 'EE',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Eswatini',	iso: 'SZ',	currency_iso: 'SZL',	currency_name: 'Lilangeni'},
  {name: 'Etats-Unis',	iso: 'US',	currency_iso: 'USD',	currency_name: 'Dollar américain'},
  {name: 'Ethiopie',	iso: 'ET',	currency_iso: 'ETB',	currency_name: 'Birr éthiopien'},
  {name: 'Fidji',	iso: 'FJ',	currency_iso: 'FJD',	currency_name: 'Dollar de Fidji'},
  {name: 'Finlande',	iso: 'FI',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'France',	iso: 'FR',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Gabon',	iso: 'GA',	currency_iso: 'XAF',	currency_name: 'Franc CFA'},
  {name: 'Gambie',	iso: 'GM',	currency_iso: 'GMD',	currency_name: 'Dalasi gambien'},
  {name: 'Géorgie',	iso: 'GE',	currency_iso: 'GEL',	currency_name: 'Lari'},
  {name: 'Ghana',	iso: 'GH',	currency_iso: 'GHS',	currency_name: 'Cedi'},
  {name: 'Grèce',	iso: 'GR',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Grenade',	iso: 'GD',	currency_iso: 'XCD',	currency_name: 'Dollar des Caraïbes orientales'},
  {name: 'Guatemala',	iso: 'GT',	currency_iso: 'GTQ',	currency_name: 'Quetzal guatémaltèque'},
  {name: 'Guinée',	iso: 'GN',	currency_iso: 'GNF',	currency_name: 'Franc guinéen'},
  {name: 'Guinée équatoriale',	iso: 'GQ',	currency_iso: 'XAF',	currency_name: 'Franc CFA'},
  {name: 'Guinée-Bissau',	iso: 'GW',	currency_iso: 'XOF',	currency_name: 'Franc CFA'},
  {name: 'Guyana',	iso: 'GY',	currency_iso: 'GYD',	currency_name: 'Dollar guyanien'},
  {name: 'Haïti',	iso: 'HT',	currency_iso: 'HTG',	currency_name: 'Gourde haïtienne'},
  {name: 'Honduras',	iso: 'HN',	currency_iso: 'HNL',	currency_name: 'Lempira hondurien'},
  {name: 'Hongrie',	iso: 'HU',	currency_iso: 'HUF',	currency_name: 'Forint hongrois'},
  {name: 'Île Maurice',	iso: 'MU',	currency_iso: 'MUR',	currency_name: 'Roupie mauricienne'},
  {name: 'Inde',	iso: 'IN',	currency_iso: 'INR',	currency_name: 'Roupie indienne'},
  {name: 'Indonésie',	iso: 'ID',	currency_iso: 'IDR',	currency_name: 'Roupie indonésienne'},
  {name: 'Irak',	iso: 'IQ',	currency_iso: 'IQD',	currency_name: 'Dinar irakien'},
  {name: 'Iran',	iso: 'IR',	currency_iso: 'IRR',	currency_name: 'Rial iranien'},
  {name: 'Irlande',	iso: 'IE',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Islande',	iso: 'IS',	currency_iso: 'ISK',	currency_name: 'Couronne islandaise'},
  {name: 'Israël',	iso: 'IL',	currency_iso: 'ILS',	currency_name: 'Shekel'},
  {name: 'Italie',	iso: 'IT',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Jamaïque',	iso: 'JM',	currency_iso: 'JMD',	currency_name: 'Dollar jamaïcain'},
  {name: 'Japon',	iso: 'JP',	currency_iso: 'JPY',	currency_name: 'Yen'},
  {name: 'Jordanie',	iso: 'JO',	currency_iso: 'JOD',	currency_name: 'Dinar jordanien'},
  {name: 'Kazakhstan',	iso: 'KZ',	currency_iso: 'KZT',	currency_name: 'Tenge'},
  {name: 'Kenya',	iso: 'KE',	currency_iso: 'KES',	currency_name: 'Shilling kényan'},
  {name: 'Kirghizistan',	iso: 'KG',	currency_iso: 'KGS',	currency_name: 'Som'},
  {name: 'Kiribati',	iso: 'KI',	currency_iso: 'AUD',	currency_name: 'Dollar australien'},
  {name: 'Kosovo',	iso: '-',	currency_iso: 'EUR',	currency_name: 'Euro (de facto)'},
  {name: 'Koweït',	iso: 'KW',	currency_iso: 'KWD',	currency_name: 'Dinar koweïtien'},
  {name: 'Laos',	iso: 'LA',	currency_iso: 'LAK',	currency_name: 'Kip'},
  {name: 'Lesotho',	iso: 'LS',	currency_iso: 'LSL',	currency_name: 'Loti lesothan'},
  {name: 'Lettonie',	iso: 'LV',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Liban',	iso: 'LB',	currency_iso: 'LBP',	currency_name: 'Livre libanaise'},
  {name: 'Liberia',	iso: 'LR',	currency_iso: 'LRD',	currency_name: 'Dollar libérien'},
  {name: 'Libye',	iso: 'LY',	currency_iso: 'LYD',	currency_name: 'Dinar libyen'},
  {name: 'Liechtenstein',	iso: 'LI',	currency_iso: 'CHF',	currency_name: 'Franc suisse'},
  {name: 'Lituanie',	iso: 'LT',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Luxembourg',	iso: 'LU',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Macédoine du Nord',	iso: 'MK',	currency_iso: 'MKD',	currency_name: 'Denar'},
  {name: 'Madagascar',	iso: 'MG',	currency_iso: 'MGA',	currency_name: 'Ariary malgache'},
  {name: 'Malaisie',	iso: 'MY',	currency_iso: 'MYR',	currency_name: 'Ringgit'},
  {name: 'Malawi',	iso: 'MW',	currency_iso: 'MWK',	currency_name: 'Kwacha malawien'},
  {name: 'Maldives',	iso: 'MV',	currency_iso: 'MVR',	currency_name: 'Rufiyaa'},
  {name: 'Mali',	iso: 'ML',	currency_iso: 'XOF',	currency_name: 'Franc CFA'},
  {name: 'Malte',	iso: 'MT',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Maroc',	iso: 'MA',	currency_iso: 'MAD',	currency_name: 'Dirham marocain'},
  {name: 'Marshall',	iso: 'MH',	currency_iso: 'USD',	currency_name: 'Dollar américain'},
  {name: 'Mauritanie',	iso: 'MR',	currency_iso: 'MRO',	currency_name: 'Ouguiya mauritanien'},
  {name: 'Mexique',	iso: 'MX',	currency_iso: 'MXN',	currency_name: 'Peso mexicain'},
  {name: 'Micronésie',	iso: 'FM',	currency_iso: 'USD',	currency_name: 'Dollar américain'},
  {name: 'Moldavie',	iso: 'MD',	currency_iso: 'MDL',	currency_name: 'Leu moldave'},
  {name: 'Monaco',	iso: 'MC',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Mongolie',	iso: 'MN',	currency_iso: 'MNT',	currency_name: 'Tugrik'},
  {name: 'Monténégro',	iso: 'ME',	currency_iso: 'EUR',	currency_name: 'Euro (de facto)'},
  {name: 'Mozambique',	iso: 'MZ',	currency_iso: 'MZN',	currency_name: 'Metical mozambicain'},
  {name: 'Namibie',	iso: 'NA',	currency_iso: 'NAD',	currency_name: 'Dollar namibien'},
  {name: 'Nauru',	iso: 'NR',	currency_iso: 'AUD',	currency_name: 'Dollar australien'},
  {name: 'Népal',	iso: 'NP',	currency_iso: 'NPR',	currency_name: 'Roupie népalaise'},
  {name: 'Nicaragua',	iso: 'NI',	currency_iso: 'NIO',	currency_name: 'Córdoba nicaraguayen'},
  {name: 'Niger',	iso: 'NE',	currency_iso: 'XOF',	currency_name: 'Franc CFA'},
  {name: 'Nigeria',	iso: 'NG',	currency_iso: 'NGN',	currency_name: 'Naira nigérian'},
  {name: 'Norvège',	iso: 'NO',	currency_iso: 'NOK',	currency_name: 'Couronne norvégienne'},
  {name: 'Nouvelle-Zélande',	iso: 'NZ',	currency_iso: 'NZD',	currency_name: 'Dollar néo-zélandais'},
  {name: 'Oman',	iso: 'OM',	currency_iso: 'OMR',	currency_name: 'Rial omanais'},
  {name: 'Ouganda',	iso: 'UG',	currency_iso: 'UGX',	currency_name: 'Shilling ougandais'},
  {name: 'Ouzbékistan',	iso: 'UZ',	currency_iso: 'UZS',	currency_name: 'Sum'},
  {name: 'Pakistan',	iso: 'PK',	currency_iso: 'PKR',	currency_name: 'Roupie pakistanaise'},
  {name: 'Palaos',	iso: 'PW',	currency_iso: 'USD',	currency_name: 'Dollar américain'},
  {name: 'Palestine',	iso: 'PS',	currency_iso: 'ILS',	currency_name: 'Shekel israélien'},
  {name: 'Panama',	iso: 'PA',	currency_iso: 'PAB',	currency_name: 'Balboa panaméen'},
  {name: 'Papouasie-Nouvelle-Guinée',	iso: 'PG',	currency_iso: 'PGK',	currency_name: 'Kina'},
  {name: 'Paraguay',	iso: 'PY',	currency_iso: 'PYG',	currency_name: 'Guaraní paraguayen'},
  {name: 'Pays-Bas',	iso: 'NL',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Pérou',	iso: 'PE',	currency_iso: 'PEN',	currency_name: 'Nuevo sol péruvien'},
  {name: 'Philippines',	iso: 'PH',	currency_iso: 'PHP',	currency_name: 'Peso philippin'},
  {name: 'Pologne',	iso: 'PL',	currency_iso: 'PLN',	currency_name: 'Zloty polonais'},
  {name: 'Portugal',	iso: 'PT',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Qatar',	iso: 'QA',	currency_iso: 'QAR',	currency_name: 'Riyal qatari'},
  {name: 'République Centrafricaine',	iso: 'CF',	currency_iso: 'XAF',	currency_name: 'Franc CFA'},
  {name: 'République Démocratique du Congo',	iso: 'CD',	currency_iso: 'CDF',	currency_name: 'Franc congolais'},
  {name: 'République Dominicaine',	iso: 'DO',	currency_iso: 'DOP',	currency_name: 'Peso dominicain'},
  {name: 'République Tchèque',	iso: 'CZ',	currency_iso: 'CZK',	currency_name: 'Couronne tchèque'},
  {name: 'Roumanie',	iso: 'RO',	currency_iso: 'RON',	currency_name: 'Leu roumain'},
  {name: 'Royaume-Uni',	iso: 'GB',	currency_iso: 'GBP',	currency_name: 'Livre sterling'},
  {name: 'Russie',	iso: 'RU',	currency_iso: 'RUB',	currency_name: 'Rouble russe'},
  {name: 'Rwanda',	iso: 'RW',	currency_iso: 'RWF',	currency_name: 'Franc rwandais'},
  {name: 'Saint-Kitts-et-Nevis',	iso: 'KN',	currency_iso: 'XCD',	currency_name: 'Dollar des Caraïbes orientales'},
  {name: 'Saint-Marin',	iso: 'SM',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Saint-Vincent-et-les-Grenadines',	iso: 'VC',	currency_iso: 'XCD',	currency_name: 'Dollar des Caraïbes orientales'},
  {name: 'Sainte-Lucie',	iso: 'LC',	currency_iso: 'XCD',	currency_name: 'Dollar des Caraïbes orientales'},
  {name: 'Salomon',	iso: 'SB',	currency_iso: 'SBD',	currency_name: 'Dollar des îles Salomon'},
  {name: 'Salvador',	iso: 'SV',	currency_iso: 'USD',	currency_name: 'Dollar américain'},
  {name: 'Samoa',	iso: 'WS',	currency_iso: 'WST',	currency_name: 'Tala'},
  {name: 'São Tomé et Príncipe',	iso: 'ST',	currency_iso: 'STD',	currency_name: 'Dobra santoméen'},
  {name: 'Sénégal',	iso: 'SN',	currency_iso: 'XOF',	currency_name: 'Franc CFA'},
  {name: 'Serbie',	iso: 'RS',	currency_iso: 'RSD',	currency_name: 'Dinar serbe'},
  {name: 'Seychelles',	iso: 'SC',	currency_iso: 'SCR',	currency_name: 'Roupie seychelloise'},
  {name: 'Sierra Leone',	iso: 'SL',	currency_iso: 'SLL',	currency_name: 'Leone sierra-léonais'},
  {name: 'Singapour',	iso: 'SG',	currency_iso: 'SGD',	currency_name: 'Dollar de Singapour'},
  {name: 'Slovaquie',	iso: 'SK',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Slovénie',	iso: 'SI',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Somalie',	iso: 'SO',	currency_iso: 'SOS',	currency_name: 'Shilling somalien'},
  {name: 'Soudan',	iso: 'SD',	currency_iso: 'SDG',	currency_name: 'Livre soudanaise'},
  {name: 'Soudan du Sud',	iso: 'SS',	currency_iso: 'SSP',	currency_name: 'Livre sud-soudanaise'},
  {name: 'Sri Lanka',	iso: 'LK',	currency_iso: 'LKR',	currency_name: 'Roupie srilankaise'},
  {name: 'Suède',	iso: 'SE',	currency_iso: 'SEK',	currency_name: 'Couronne suédoise'},
  {name: 'Suisse',	iso: 'CH',	currency_iso: 'CHF',	currency_name: 'Franc suisse'},
  {name: 'Suriname',	iso: 'SR',	currency_iso: 'SRD',	currency_name: 'Dollar de Surinam'},
  {name: 'Syrie',	iso: 'SY',	currency_iso: 'SYP',	currency_name: 'Livre syrienne'},
  {name: 'Tadjikistan',	iso: 'TJ',	currency_iso: 'TJS',	currency_name: 'Somoni'},
  {name: 'Taïwan',	iso: 'TW',	currency_iso: 'TWD',	currency_name: 'Nouveau dollar de Taïwan'},
  {name: 'Tanzanie',	iso: 'TZ',	currency_iso: 'TZS',	currency_name: 'Shilling tanzanien'},
  {name: 'Tchad',	iso: 'TD',	currency_iso: 'XAF',	currency_name: 'Franc CFA'},
  {name: 'Thaïlande',	iso: 'TH',	currency_iso: 'THB',	currency_name: 'Baht'},
  {name: 'Timor-Oriental',	iso: 'TL',	currency_iso: 'USD',	currency_name: 'Dollar américain'},
  {name: 'Togo',	iso: 'TG',	currency_iso: 'XOF',	currency_name: 'Franc CFA'},
  {name: 'Tonga',	iso: 'TO',	currency_iso: 'TOP',	currency_name: 'Pa\'anga'},
  {name: 'Trinité-et-Tobago',	iso: 'TT',	currency_iso: 'TTD',	currency_name: 'Dollar trinidadien'},
  {name: 'Tunisie',	iso: 'TN',	currency_iso: 'TND',	currency_name: 'Dinar tunisien'},
  {name: 'Turkménistan',	iso: 'TM',	currency_iso: 'TMT',	currency_name: 'Manat turkmène'},
  {name: 'Turquie',	iso: 'TR',	currency_iso: 'TRY',	currency_name: 'Livre turque'},
  {name: 'Tuvalu',	iso: 'TV',	currency_iso: 'AUD',	currency_name: 'Dollar australien'},
  {name: 'Ukraine',	iso: 'UA',	currency_iso: 'UAH',	currency_name: 'Hryvnia'},
  {name: 'Uruguay',	iso: 'UY',	currency_iso: 'UYU',	currency_name: 'Peso uruguayen'},
  {name: 'Vanuatu',	iso: 'VU',	currency_iso: 'VUV',	currency_name: 'Vatu'},
  {name: 'Vatican',	iso: 'VA',	currency_iso: 'EUR',	currency_name: 'Euro'},
  {name: 'Venezuela',	iso: 'VE',	currency_iso: 'VEF',	currency_name: 'Bolívar vénézuélien'},
  {name: 'Viêt Nam',	iso: 'VN',	currency_iso: 'VND',	currency_name: 'Dong'},
  {name: 'Yémen',	iso: 'YE',	currency_iso: 'YER',	currency_name: 'Rial yéménite'},
  {name: 'Zambie',	iso: 'ZM',	currency_iso: 'ZMW',	currency_name: 'Kwacha zambien'},
  {name: 'Zimbabwe',	iso: 'ZW',	currency_iso: 'USD',	currency_name: 'Dollar américain'},
]

export const debounceTimeOfSearch = 1000;


export const translateBackendErrorMessage = (errorMessage: string): string => {
  const message1Regex = /The (declass|claim|pierced|missing|not_sale) quantity (\w+) cannot be modified because the quantity to be modified is greater than the quantity of the return product./;
  const message2Regex = /The (\w+) cannot be modified because the total quantities received by the sector are less than the total quantities issued./;

  let matches = errorMessage.match(message1Regex);
  if (matches && matches.length === 3) {
    const quantityType = matches[1]; // 'declass|claim|pierced|missing|not_sale'
    const productCode = matches[2];  // 'Ex: DCL4500'
    return translateErrorMessageUnloading(quantityType, productCode);
  }

  matches = errorMessage.match(message2Regex);
  if (matches && matches.length === 2) {
    const productCode = matches[1];  // 'Ex: DSV4500'
    return translateErrorMessageMovementStock (productCode);
  }
  return errorMessage; // Retourne un message d'erreur par défaut si le format ne correspond pas
}

export const translateErrorMessageUnloading = (quantityType: string, productCode: string): string => {
  // Traduction du message d'erreur en français
  const quantityTypeTranslations: { [key: string]: string } = {
    declass: "déclassée",
    claim: "réclamée",
    pierced: "percée",
    missing: "manquante",
    not_sale: "invendue"
  };
  const translatedQuantityType = quantityTypeTranslations[quantityType] || quantityType;
  return `La quantité ${translatedQuantityType} pour le produit ${productCode} ne peut pas être modifiée car la quantité à modifier est supérieure à la quantité du produit retourné.`;
}

export const  translateErrorMessageMovementStock = (productCode: string): string => {
  return `Le produit ${productCode} ne peut pas être modifié car les quantités totales reçues par le secteur sont inférieures aux quantités totales émises.`;
}

export const translateErrorMessageLoadingUpdate = (errorMessage: string): string => {
  let translateMessage = errorMessage;
  if (errorMessage.includes('At least one loadings not found')) {
    translateMessage = `Au moins un chargement n'a pas été trouvé`;
  }
  if (errorMessage.includes('The loadings do not belong to the same tour')) {
    translateMessage = `Les chargements n'appartiennent pas à la même tournée.`
  }
  if (errorMessage.includes('The loadings do not belong to the tour')) {
    translateMessage = `Les chargements n'appartiennent pas à la tournée.`
  }
  return translateMessage;
}
