<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <div class="d-flex">
    <app-go-back></app-go-back>
    <h1 class="h2 text-info">Performances - Points de vente</h1>
  </div>
  <div class="btn-toolbar mb-2 mb-md-0">
    <div class="row">
      <div class="col-xl-4 col-sm-6 col-12 mb-4">
        <div class="row row-cols-sm-auto form-group mx-3">
          <div class="col-12 d-flex flex-row">
            <label class="col-sm-5 col-form-label fw-bold text-info"
              >Secteur
            </label>
            <div class="col">
              <div class="input-group">
                <select
                  [(ngModel)]="sector"
                  (ngModelChange)="onSectorChange($event)"
                  class="form-control form-control-sm"
                  aria-label="Choisir un secteur"
                >
                  <option [ngValue]="undefined" selected>
                    Choisir un secteur
                  </option>
                  <option *ngFor="let sector of sectors" [ngValue]="sector.id">
                    {{ sector.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-6 col-12 mb-4">
        <div class="row row-cols-sm-auto form-group mx-3">
          <div class="col-12 d-flex flex-row">
            <label class="col-sm-2 col-form-label fw-bold text-info">Du:</label>
            <div class="col">
              <div class="input-group">
                <input
                  ngb-dp-today
                  [(ngModel)]="startDate"
                  (ngModelChange)="onDateChange($event)"
                  class="form-control"
                  placeholder="dd/mm/yyyy"
                  name="start_date"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  [maxDate]="maxDate"
                />
                <button
                  class="btn btn-outline-secondary calendar bg-info"
                  (click)="d.toggle()"
                  type="button"
                >
                  <i-bs name="calendar3" class="text-white"></i-bs>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-6 col-12 mb-4">
        <div class="row row-cols-sm-auto form-group mx-3">
          <div class="col-12 d-flex flex-row">
            <label class="col-sm-2 col-form-label fw-bold text-info">Au:</label>
            <div class="col">
              <div class="input-group">
                <input
                  ngb-dp-today
                  [(ngModel)]="endDate"
                  (ngModelChange)="onDateChange($event)"
                  class="form-control"
                  placeholder="dd/mm/yyyy"
                  name="end_date"
                  ngbDatepicker
                  #e="ngbDatepicker"
                  [minDate]="minDate"
                />
                <button
                  class="btn btn-outline-secondary calendar bg-info"
                  (click)="e.toggle()"
                  type="button"
                >
                  <i-bs name="calendar3" class="text-white"></i-bs>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="performances.length > 0">
  <div class="col-xl-4 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3 class="text-info">
              {{
                getCATotal() | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
              }}
            </h3>
            <p class="mb-0 fw-bold">CA Total</p>
          </div>
          <div class="align-self-center">
            <i class="fas fa-rocket text-info fa-3x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3 class="text-info">
              {{
                getTotalReturns()
                  | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
              }}
            </h3>
            <p class="mb-0 fw-bold">Montant Total des Retours</p>
          </div>
          <div class="align-self-center">
            <i class="fas fa-rocket text-info fa-3x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3 class="text-info">{{ getReturnPercentageTotal() }} %</h3>
            <p class="mb-0 fw-bold">Taux de retours</p>
          </div>
          <div class="align-self-center">
            <i class="fas fa-rocket text-info fa-3x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="table-responsive">
  <table class="table align-middle table-hover">
    <thead class="table-light">
      <tr class="rounded">
        <th scope="col">ID</th>
        <th scope="col">PDV</th>
        <th scope="col" class="text-center">CA</th>
        <th scope="col" class="text-center">MT Retours</th>
        <th scope="col" class="text-center">Tx de retour</th>
        <th scope="col" class="text-center">Détails ventes</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pointOfSale of this.pointOfSales">
        <th scope="row" class="fw-normal">{{ pointOfSale.id }}</th>
        <td class="fw-bold text-info">
          {{ pointOfSale.name }}
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{
              getCA(pointOfSale.id)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}
          </span>
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{
              getReturns(pointOfSale.id)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}
          </span>
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{ getReturnPercentage(pointOfSale.id) }} %
          </span>
        </td>
        <td class="text-center text-info fw-bold">
          <span class="text-center btn btn-sm btn-info">
            <a
              class="text-info text-white"
              routerLink="/performances/point-of-sale/{{ pointOfSale.id }}"
              [queryParams]="{
                name: pointOfSale.name,
                id: pointOfSale.id,
                start_date: startDate,
                end_date: endDate
              }"
            >
              <i-bs name="eye"></i-bs>
            </a>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
