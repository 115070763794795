<div
  class="d-flex flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <app-go-back></app-go-back>
  <h1 class="h3 text-info">
    Bon de commande -
    <span class="fs-3 text-secondary">{{ point_of_sale_name }}</span> -
    <span class="text-info">{{ date }}</span>
  </h1>
</div>
<form [formGroup]="newOrderForm" (ngSubmit)="onSubmit()">
  <table class="table align-middle mb-5">
    <thead class="table-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Produits</th>
        <th scope="col">Propositions de commande</th>
        <th scope="col">Commande</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let product of products; index as i">
        <th scope="row">{{ i + 1 }}</th>
        <td class="fw-bold text-info">
          {{ product.sku_sage }}
        </td>
        <td>
          {{ getProposed(product.id) }}
        </td>
        <td>
          <div class="row">
            <div class="col-1">
              <app-is-guarded [permissions]="['CAN_EDIT_ORDER']">
                <button
                  type="button"
                  class="btn btn-sm btn-info text-white fw-bold"
                  (click)="
                    decrementQuantity(
                      product.id,
                      getProductPrice(product.price)
                    )
                  "
                >
                  <i-bs name="dash"></i-bs>
                </button>
              </app-is-guarded>
            </div>

            <div class="mx-4 col-3">
              <input
                class="form-control form-control-sm"
                type="number"
                (keydown)="($event.keyCode !== 69)"
                (keyup)="
                  onKey(
                    $event,
                    getProductPrice(product.price),
                    getOrderProductId(product.id),
                    product.id,
                    orderId
                  )
                "
                value="{{ getQuantityOrdered(product.id) }}"
              />
            </div>
            <div class="col-1">
              <app-is-guarded [permissions]="['CAN_EDIT_ORDER']">
                <button
                  type="button"
                  class="btn btn-sm btn-info text-white fw-bold"
                  (click)="
                    incrementQuantity(
                      product.id,
                      getProductPrice(product.price)
                    )
                  "
                >
                  <i-bs name="plus"></i-bs>
                </button>
              </app-is-guarded>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>
<app-is-guarded [permissions]="['CAN_EDIT_ORDER']">
  <div
    *ngIf="getSumQuantityOrdered()"
    class="bottom-bar shadow-sm fixed-bottom col-md-9 ms-sm-auto col-lg-10 px-md-4"
  >
    <div class="justify-content-end mb-md-0 mx-1 d-flex">
      <div class="d-flex flex-wrap flex-md-nowrap">
        <div class="mx-2">
          <button
            type="button"
            class="btn btn-sm btn-info fw-bold text-white"
            (click)="onSubmit()"
          >
            Enregistrer la commande
          </button>
        </div>
      </div>
    </div>
  </div>
</app-is-guarded>
