<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <h1 class="h2 text-info">Performances globales</h1>
  <div class="btn-toolbar mb-2 mb-md-0">
    <div class="row">
      <div class="col">
        <div class="row form-group mx-3">
          <label for="inputEmail3" class="col-form-label fw-bold text-info"
            >Secteurs</label
          >
          <div class="col">
            <ng-select
              [(ngModel)]="selectedSectors"
              [multiple]="true"
              [maxSelectedItems]="2"
              (change)="onSelectedSector()"
            >
              <ng-option *ngFor="let sector of sectors" [value]="sector.id">{{
                sector.label
              }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row form-group mx-3">
          <label for="inputEmail3" class="col-form-label fw-bold text-info"
            >Mois
          </label>
          <div class="col">
            <select
              [(ngModel)]="month"
              (ngModelChange)="onMonthChange($event)"
              class="form-control form-control-sm"
              aria-label="Choisir un mois"
            >
              <option [ngValue]="undefined" selected>Choisir un mois</option>
              <option *ngFor="let m of months" [ngValue]="m.id">
                {{ m.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row form-group mx-4">
          <label for="inputEmail3" class="col-form-label fw-bold text-info"
            >Année
          </label>
          <div class="col">
            <select
              [(ngModel)]="year"
              (ngModelChange)="onYearChange($event)"
              class="form-control form-control-sm"
              aria-label="Choisir une année"
            >
              <option [ngValue]="undefined" selected>Choisir une année</option>
              <option *ngFor="let y of years" [ngValue]="y.id">
                {{ y.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="performances.length > 0">
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3 class="text-info">
              {{
                getCATotal() | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
              }}
            </h3>
            <p class="mb-0 fw-bold">CA Total</p>
          </div>
          <div class="align-self-center">
            <i class="fas fa-rocket text-info fa-3x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3 class="text-info">
              {{
                getTotalReturns()
                  | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
              }}
            </h3>
            <p class="mb-0 fw-bold">Montant Total des Retours</p>
          </div>
          <div class="align-self-center">
            <i class="fas fa-rocket text-info fa-3x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3 class="text-info">{{ getReturnPercentageTotal() }} %</h3>
            <p class="mb-0 fw-bold">Taux de retours</p>
          </div>
          <div class="align-self-center">
            <i class="fas fa-rocket text-info fa-3x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6 col-12 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between px-md-1">
          <div>
            <h3 class="text-info">
              {{
                getProjectionTotal()
                  | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
              }}
            </h3>
            <p class="mb-0 fw-bold">Projection total</p>
          </div>
          <div class="align-self-center">
            <i class="fas fa-rocket text-info fa-3x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="table-responsive">
  <table
    *ngIf="selectedSectors!.length == 0"
    class="table align-middle table-hover"
  >
    <thead class="table-light">
      <tr class="rounded">
        <th scope="col">ID</th>
        <th scope="col">Secteurs</th>
        <th scope="col" class="text-center">Objectifs</th>
        <th scope="col" class="text-center">CA</th>
        <th scope="col" class="text-center">MT Retours</th>
        <th scope="col" class="text-center">Tx de retour</th>
        <th scope="col" class="text-center">Projection</th>
        <th scope="col" class="text-center">Détails ventes</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let sector of this.sectors">
        <th scope="row" class="fw-normal">{{ sector.id }}</th>
        <td class="fw-bold text-info">
          {{ sector.label }}
        </td>
        <td
          class="text-primary fw-bold {{
            getGoalCAClass(sector.id!, getCA(sector.id!))
          }}"
        >
          <span class="float-end">
            {{
              getGoalCA(sector.id!)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}
          </span>
        </td>
        <td
          class="text-primary fw-bold {{
            getGoalCAClass(sector.id!, getCA(sector.id!))
          }}"
        >
          <span class="float-end">
            {{
              getCA(sector.id!)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}
          </span>
        </td>
        <td
          class="text-primary fw-bold {{
            getGoalReturnRateClass(sector.id!, getReturns(sector.id!))
          }}"
        >
          <i-bs
            *ngIf="isGoalCAReached(sector.id!, getCA(sector.id!))"
            name="caret-up-fill"
            class="text-success"
          ></i-bs>
          <i-bs
            *ngIf="!isGoalCAReached(sector.id!, getCA(sector.id!))"
            name="caret-down-fill"
            class="text-danger"
          ></i-bs>
          <span class="float-end">
            {{
              getReturns(sector.id!)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}
          </span>
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{ getReturnPercentage(sector.id!) }} %
          </span>
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{
              getProjection(sector.id!)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}
          </span>
        </td>
        <td class="text-center text-info fw-bold">
          <span class="text-center btn btn-info">
            <a
              class="text-info text-white"
              routerLink="/performances/point-of-sales"
              [queryParams]="{
                sector_id: sector.id,
                start_date: startDate,
                end_date: endDate
              }"
            >
              <i-bs name="eye"></i-bs>
            </a>
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <table
    *ngIf="selectedSectors!.length > 0"
    class="table align-middle table-hover"
  >
    <thead class="table-light">
      <tr class="rounded">
        <th scope="col">ID</th>
        <th scope="col">Secteurs</th>
        <th scope="col" class="text-center">CA</th>
        <th scope="col" class="text-center">MT Retours</th>
        <th scope="col" class="text-center">Tx de retour</th>
        <th scope="col" class="text-center">Projection</th>
        <th scope="col" class="text-center">Détails ventes</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let performance of this.performances">
        <th scope="row" class="fw-normal">{{ performance.sector_id! }}</th>
        <td class="fw-bold text-info">
          {{ getSectorLabel(performance.sector_id!) }}
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{
              getCA(performance.sector_id!)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}
          </span>
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{
              getReturns(performance.sector_id!)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}
          </span>
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{ getReturnPercentage(performance.sector_id!) }} %
          </span>
        </td>
        <td class="text-primary fw-bold">
          <span class="float-end">
            {{
              getProjection(performance.sector_id!)
                | currency : "XOF" : "symbol-narrow" : "" : "fr-FR"
            }}
          </span>
        </td>
        <td class="text-center text-info fw-bold">
          <span class="text-center btn btn-sm btn-info">
            <a
              class="text-info text-white"
              routerLink="/performances/point-of-sales"
              [queryParams]="{
                sector_id: performance.sector_id!,
                start_date: startDate,
                end_date: endDate
              }"
            >
              <i-bs name="eye"></i-bs>
            </a>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
