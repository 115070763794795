<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="description" content="" />
    <meta name="author" content="Laiterie du berger" />
    <meta name="generator" content="Hugo 0.88.1" />
    <title>Leuk | Back-Office</title>
  </head>
  <body>
    <app-toast aria-live="polite" aria-atomic="true"></app-toast>
    <header
      class="navbar navbar-dark sticky-top bg-primary flex-md-nowrap p-0 shadow"
    >
      <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#"
        >Leuk Back-office
        <span class="badge text-bg-secondary text-sm">{{
          currentApplicationVersion
        }}</span></a
      >
      <button
        (click)="collapse.toggle()"
        [attr.aria-expanded]="!isCollapsed"
        class="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <ul class="navbar-nav">
        <li ngbDropdown class="nav-item d-inline-block user-dropdown">
          <button
            type="button"
            class="btn btn-outline-primary text-white fw-bold"
            ngbDropdownToggle
          >
            {{ currentUser?.full_name }}
          </button>
          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
            <a ngbDropdownItem (click)="logout()" href="#">Déconnexion</a>
          </div>
        </li>
      </ul>
    </header>

    <div class="layout-container-fluid container-fluid">
      <div class="row">
        <nav
          #collapse="ngbCollapse"
          [(ngbCollapse)]="isCollapsed"
          id="sidebarMenu"
          class="col-md-3 col-lg-2 d-md-block bg-light sidebar"
          [ngClass]="{ resize: resized }"
        >
          <div
            class="menu position-sticky pt-3 -flex flex-column flex-shrink-0"
          >
            <ul class="nav flex-column">
              <ng-template ngFor let-menu [ngForOf]="MENU_ITEMS">
                <app-nav-link
                  [resized]="resized"
                  *ngIf="canViewMenu(menu)"
                  [menu]="menu"
                ></app-nav-link>
              </ng-template>
            </ul>
            <div
              class="version-section text-primary d-block bg-info py-2 align-items-center align-middle"
            >
              <button
                class="btn btn-link d-block text-decoration-none text-white p-0"
                (click)="resize()"
              >
                <span>
                  <i-bs
                    [name]="
                      resized ? 'chevron-double-right' : 'chevron-double-left'
                    "
                    class="align-text-bottom align-middle"
                  ></i-bs>
                  <span
                    *ngIf="!resized"
                    class="align-text-bottom align-middle px-2 fw-bold"
                    >Réduire</span
                  >
                </span>
              </button>
            </div>
          </div>
        </nav>

        <main
          class=""
          [ngClass]="{
            'col-md-9 ms-sm-auto col-lg-10 px-md-4': !resized,
            'resize-margin': resized
          }"
        >
          <router-outlet></router-outlet>
          <ngx-spinner
            bdColor="rgba(0, 0, 0, 0.8)"
            size="medium"
            color="#fff"
            type="square-jelly-box"
            [fullScreen]="true"
            ><p style="color: white">Chargement...</p></ngx-spinner
          >
        </main>
      </div>
    </div>
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
    <!-- <script src="dashboard.js"></script> -->
  </body>
</html>
