<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <div class="d-flex">
    <app-go-back></app-go-back>
    <h1 class="h3 text-info">Objectifs des régions commerciales</h1>
  </div>
  <div class="d-flex row mx-3 my-2">
    <div class="col-sm-6">
      <div class="row form-group">
        <label for="inputEmail3" class="col-form-label fw-bold text-info"
          >Mois
        </label>
        <div class="col">
          <select
            [(ngModel)]="month"
            (ngModelChange)="onMonthChange($event)"
            class="form-control form-control-sm"
            aria-label="Choisir un mois"
          >
            <option [ngValue]="undefined" selected>Choisir un mois</option>
            <option *ngFor="let m of months" [ngValue]="m.id">
              {{ m.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="row form-group">
        <label for="inputEmail3" class="col-form-label fw-bold text-info"
          >Année
        </label>
        <div class="col">
          <select
            [(ngModel)]="year"
            (ngModelChange)="onYearChange($event)"
            class="form-control form-control-sm"
            aria-label="Choisir une année"
          >
            <option [ngValue]="undefined" selected>Choisir une année</option>
            <option *ngFor="let y of years" [ngValue]="y.id">
              {{ y.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-3 row mx-3 my-2">
    <app-is-guarded [permissions]="['CAN_CREATE_GOAL_RC']">
      <div class="w-100">
        <div class="col-sm-12">
          <a
            class="btn btn-info btn-sm d-block text-sm text-white fw-bold rounded"
            (click)="openCreateModal()"
            >Nouvel objectif</a
          >
        </div>
      </div>
    </app-is-guarded>
  </div>
</div>
<div>
  <app-table
    *ngIf="!loading && goals?.length"
    [columnDefs]="columnDefs"
    [tableData]="goals"
    (actionInvoqueFunctionEvent)="tableActionsFunction($event)"
  ></app-table>
  <ng-template [ngIf]="!loadingRCGoals && !goals.length">
    <app-empty-card
      [icon]="'exclamation-circle'"
      [message]="'Aucun objectif trouvé'"
    ></app-empty-card>
  </ng-template>
  <app-pagination
    *ngIf="!loadingRCGoals && goals.length"
    [pagination]="pagination"
    (paginationEvent)="paginate($event)"
  ></app-pagination>

  <swal #modal (confirm)="confirmModal()" (dismiss)="cancel()">
    <ng-container *swalPortal="swalTargets.content">
      <h3 class="text-info">{{ modalTitle }}</h3>
      <form *ngIf="displayFormModal" [formGroup]="modalForm">
        <div class="form-group d-flex justify-content-around">
          <div class="col-8 row-cols-sm-auto form-group p-2 mb-2">
            <label for="libelle" class="mx-2 col-form-label fw-bold text-info"
              >Région commerciale:
            </label>
            <div class="col">
              <select
                formControlName="goalable_id"
                class="form-control form-control-sm"
                aria-label="Choisir un secteur"
              >
                <option [ngValue]="undefined" selected>Choisir un mois</option>
                <option *ngFor="let rc of rCs" [ngValue]="rc.id">
                  {{ rc.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-around">
          <div class="col-8 row-cols-sm-auto form-group p-2 mb-2">
            <label for="libelle" class="mx-2 col-form-label fw-bold text-info"
              >CA:
            </label>
            <div class="col">
              <div class="input-group">
                <input
                  type="text"
                  id="ca"
                  class="form-control search-input"
                  formControlName="ca"
                  style="border-radius: 30px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-around">
          <div class="col-8 row-cols-sm-auto form-group p-2 mb-2">
            <label for="libelle" class="mx-2 col-form-label fw-bold text-info"
              >Mois:
            </label>
            <div class="col">
              <select
                formControlName="month"
                class="form-control form-control-sm"
                aria-label="Choisir un secteur"
              >
                <option [ngValue]="undefined" selected>Choisir un mois</option>
                <option *ngFor="let month of months" [ngValue]="month.id">
                  {{ month.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-around">
          <div class="col-8 row-cols-sm-auto form-group p-2 mb-2">
            <label for="libelle" class="mx-2 col-form-label fw-bold text-info"
              >Année:
            </label>
            <div class="col">
              <select
                formControlName="year"
                class="form-control form-control-sm"
                aria-label="Choisir une année"
              >
                <option [ngValue]="undefined" selected>
                  Choisir une année
                </option>
                <option *ngFor="let year of years" [ngValue]="year.id">
                  {{ year.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-around">
          <div class="col-8 row-cols-sm-auto form-group p-2 mb-2">
            <label for="libelle" class="mx-2 col-form-label fw-bold text-info"
              >Taux de retour:
            </label>
            <div class="col">
              <div class="input-group">
                <input
                  type="text"
                  id="return_rate"
                  class="form-control search-input"
                  formControlName="return_rate"
                  style="border-radius: 30px"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <p class="mt-4" *ngIf="!displayFormModal && statusActionMessage">
        {{ statusActionMessage }}
      </p>
    </ng-container>
    <ng-container *swalPortal="swalTargets.confirmButton">
      Enregistrer
    </ng-container>
    <ng-container *swalPortal="swalTargets.cancelButton">
      Annuler
    </ng-container>
  </swal>
  <modal #nGmodal [modalConfig]="modalConfig" class="modal-dialog-centered">
    <form [formGroup]="modalForm">
      <div class="mb-3">
        <label for="libelle" class="form-label fw-bold text-secondary"
          >Région commerciale:
        </label>
        <select
          formControlName="goalable_id"
          class="form-control form-control-sm"
          aria-label="Choisir un secteur"
        >
          <option class="fw-bold" [ngValue]="undefined" selected>
            Choisir une region
          </option>
          <option *ngFor="let rc of rCs" [ngValue]="rc.id">
            {{ rc.label }}
          </option>
        </select>
      </div>
      <div class="d-flex mb-3">
        <div class="col me-2">
          <label for="ca" class="form-label fw-bold text-secondary">CA: </label>
          <input
            type="text"
            id="ca"
            class="form-control"
            formControlName="ca"
          />
        </div>
        <div class="col me-2">
          <label for="return_rate" class="form-label fw-bold text-secondary"
            >Taux de retour:
          </label>
          <input
            type="text"
            id="return_rate"
            class="form-control"
            formControlName="return_rate"
          />
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="col me-2">
          <label for="libelle" class="form-label fw-bold text-secondary"
            >Mois:
          </label>
          <select
            formControlName="month"
            class="form-control"
            aria-label="Choisir un secteur"
          >
            <option [ngValue]="undefined" selected>Choisir un mois</option>
            <option *ngFor="let month of months" [ngValue]="month.id">
              {{ month.label }}
            </option>
          </select>
        </div>
        <div class="col me-2">
          <label for="year" class="form-label fw-bold text-secondary"
            >Année:
          </label>
          <select
            formControlName="year"
            class="form-control"
            aria-label="Choisir une année"
          >
            <option [ngValue]="undefined" selected>Choisir une année</option>
            <option *ngFor="let year of years" [ngValue]="year.id">
              {{ year.label }}
            </option>
          </select>
        </div>
      </div>

      <div *ngIf="modalFormType == 'UPDATE'" class="d-flex">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            formControlName="recalculate"
          />
          <label
            class="form-check-label fw-bold text-secondary"
            for="flexCheckDefault"
          >
            Recalculer les objectifs de tous les secteurs
          </label>
        </div>
      </div>
    </form>
    <p class="mt-4" *ngIf="!displayFormModal && statusActionMessage">
      {{ statusActionMessage }}
    </p>
  </modal>
</div>
